/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";


import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function Procesos() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/sections/bucaramanga.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Procesos y Procedimientos</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:'50px', paddingBottom:'30px'}}>       
          <GridContainer>
            <GridItem className="col-md-2 col-sm-12">
              <center className="mt-5">
                <a href={"https://drive.google.com/file/d/1PBZxmNNkZXp1JBPZfuqK95c_eJEX-YO0/view?usp=sharing"} style={{textDecoration: "none !important"}} target="_blank">
                  <img
                    alt="MAPA DE PROCESOS"
                    width="80px"
                    src={require("assets/img/sections/pdf.jpg")}
                  ></img>
                  <h6 className="my-3" >MAPA DE PROCESOS</h6>
                </a>
              </center>
            </GridItem>    
          </GridContainer>
          <GridContainer>
            <GridItem className="col-md-2 col-sm-12">
            <h4 className="text-center">Decreto 960 de 1970</h4>
              <a href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html" 
                role="button" className="btn btn-info" target="_black" aria-disabled="true">
                <b style={{fontSize: "16px"}}>Ver información</b>
              </a>
            </GridItem>      
          </GridContainer>
          <GridContainer>
            <GridItem className="col-md-2 col-sm-12">
              <h5 className="text-center">Decreto 1069 de 2015 Sector Justicia y del Derecho</h5>
              <a href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=74174" 
                style={{marginBottom: "25px"}}
                role="button" className="btn btn-info"  target="_black" aria-disabled="true">
                <b style={{fontSize: "16px"}}>Ver información</b>
              </a>
            </GridItem>      
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
