import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import {FiberManualRecord} from "@material-ui/icons";

import contactStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.js";

const useStyles = makeStyles(contactStyle);

export default function SectionContact() {
  const [specialitySelect, setSpecialitySelect] = React.useState("0");
  const [documentoSelect, setDocumentoSelect] = React.useState("0");
  const [solicitudSelect, setSolicitudSelect] = React.useState("0");
  const [selectedEnabled, setSelectedEnabled] = React.useState("b");
  const handleSpeciality = event => {
    setSpecialitySelect(event.target.value);
  };
 
  const handleDocumento = event => {
    setDocumentoSelect(event.target.value);
  };
  const handleSolicitud = event => {
    setSolicitudSelect(event.target.value);
  };
  const classes = useStyles();
  return (
    <div className={classes.aboutUs}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
         
          <form>
            <GridContainer>
            <GridItem md={4} sm={4}>
                <FormControl
                  fullWidth
                  className={
                    classes.selectFormControl +
                    " " +
                    classes.selectUnderlineRoot
                  }
                >
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={specialitySelect}
                    onChange={handleSpeciality}
                    inputProps={{
                      name: "specialitySelect",
                      id: "speciality-select"
                    }}
                  >
                    <MenuItem
                      disabled
                      value="0"
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Seleccionar...
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="1"
                    >
                      Persona Natural
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="2"
                    >
                      Persona Juridica
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="3"
                    >
                      Niñas, Niños y Adolecentes
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="3"
                    >
                      Apoderado
                    </MenuItem>
                  </Select>
                </FormControl>
                </GridItem>
            <GridItem md={4} sm={4}>
                <FormControl
                  fullWidth
                  className={
                    classes.selectFormControl +
                    " " +
                    classes.selectUnderlineRoot
                  }
                >
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={documentoSelect}
                    onChange={handleDocumento}
                    inputProps={{
                      name: "documentoSelect",
                      id: "documento-select"
                    }}
                  >
                    <MenuItem
                      disabled
                      value="0"
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Seleccione un tipo de documento
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="1"
                    >
                      Cedula de Extranjeria
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="2"
                    >
                      Pasaporte
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="3"
                    >
                      Registro Civil
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="3"
                    >
                      Tarjeta de Identidad
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="4"
                    >
                      Otro
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem md={4} sm={4}>
                <CustomInput
                  labelText="Numero de Identificación"
                  type="number"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <CustomInput
                  labelText="Primer Nombre"
                  type="text"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <CustomInput
                  labelText="Segundo Nombre"
                  type="text"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <CustomInput
                  labelText="Primer Apellido"
                  type="text"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <CustomInput
                  labelText="Segundo Apellido"
                  type="text"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <CustomInput
                  labelText="Email"
                  type="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <CustomInput
                  labelText="Telefono Fijo"
                  type="number"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <CustomInput
                  labelText="Telefono Movil"
                  type="number"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <CustomInput
                  labelText="Dirección"
                  type="number"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={12} sm={12}>
              <div >
                       <p>
                       La respuesta a su solicitud le será enviada a través de
                        la dirección de correspondencia o al correo electrónico,
                        por lo tanto verifique que los datos se incluyeron
                        correctamente.
                       </p>
                      </div>
              </GridItem>
              <GridItem md={4} sm={4}>
                <FormControl
                  fullWidth
                  className={
                    classes.selectFormControl +
                    " " +
                    classes.selectUnderlineRoot
                  }
                >
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={solicitudSelect}
                    onChange={handleSolicitud}
                    inputProps={{
                      name: "solicitudSelect",
                      id: "solicitud-select"
                    }}
                  >
                    <MenuItem
                      disabled
                      value="0"
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Seleccionar el tipo de solicitud
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="1"
                    >
                      Cuenta de Cobro
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="2"
                    >
                      Informes
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="3"
                    >
                      Petición Dec 491 2020
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="3"
                    >
                      Recursos de reposición
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="4"
                    >
                      Registro Ser
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem md={4} sm={3}>
                <CustomInput
                  labelText="Medio de Respuesta"
                  type="text"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                </GridItem>
                <GridItem md={4} sm={3}>
                <TextField id="filled-search" label="Detalles" type="text" variant="outlined" />
                </GridItem>
                <GridItem md={12} sm={3}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedEnabled === "a"}
                      onChange={() => setSelectedEnabled("a")}
                      value="a"
                      name="radio button enabled"
                      aria-label="A"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="He leído y estoy de acuerdo con los términos y
                  condiciones de uso de datos, implementados por el
                  Ministerio de Tecnologías de la Información y las
                  Comunicaciones"
                />
              
              
                </GridItem>
                <GridItem md={12} sm={3}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedEnabled === "a"}
                      onChange={() => setSelectedEnabled("a")}
                      value="a"
                      name="radio button enabled"
                      aria-label="A"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Certifico que los datos de contacto ingresados en mis
                  datos personales se encuentra vigentes, de igual
                  manera autorizo al Ministerio de Tecnologías de la
                  Información y las Comunicaciones para el envío de la
                  respuesta a mi solicitud por este medio."
                />
              
              
                </GridItem>
                <GridItem md={12} sm={3}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedEnabled === "a"}
                      onChange={() => setSelectedEnabled("a")}
                      value="a"
                      name="radio button enabled"
                      aria-label="A"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="No soy un robot"
                />
              
              
                </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                md={4}
                sm={4}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter
                )}
              >
                <Button color="primary" round>
                  Enviar
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
