import React from "react";
import { Routes, Route, Router, Outlet  } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Axios from 'axios'
import { cargarNotaria } from '../actions/notariaAction'
// import { CodigoNotaria, UrlApi } from "../config.js"
import "assets/css/iconosFlotantes.css";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import { Container, NavbarBrand } from "reactstrap";

// core components
import Header from "componentsPage/Header/Header.js";
import HeaderLinks from "componentsPage/Header/HeaderLinks.js";
import Footer from "../components/Footer/Footer";
// <import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

// const useStyles = makeStyles(presentationStyle);
import Home from "views/PresentationPage/PresentationPage.js";


function Pages(props) {
  // const { ...rest } = props;
  // const classes = useStyles();
  const dispatch = useDispatch()
  const notaria = useSelector((state) => state.notaria.notaria)
  // ref for the wrapper div

  // styles

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() { };
  });

  React.useEffect(() => {

    const consultar = async () => {
      let config = {
        method: 'GET',
        mode: 'cors',
        headers: { "Access-Control-Allow-Origin": true, Authorization: "Bearer " + process.env.REACT_APP_TOKEN_API },

      }

      const resultado = await Axios.get(process.env.REACT_APP_UrlApi + 'notaria', config)

      if (resultado.status === 200) {
        let dataTemp = {}
        resultado.data.forEach((element) => {
          dataTemp[element.variable] = element.value
        })
        dispatch(cargarNotaria(dataTemp))
      }
    }
    consultar()
  }, []);

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.children) {
        return getRoutes(prop.children);
      }
      if (prop.layout === "/page") {
        return (
          <Route
            path={prop.layout + prop.path}
            element={prop.element}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  return (
    <div>
      <Header
        brand="Página Notaría"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "dark"
        }}
      />
      <Outlet />     
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
      <a href={"https://wa.me/" + notaria.contacto_whatsapp} className="float" target="_blank">
        <i className="fa fa-whatsapp my-float"></i>
      </a>
      <Footer/>
    </div >
  );
}

export default Pages