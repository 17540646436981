import React from "react";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {AssignmentInd} from "@material-ui/icons";
import {AssignmentTurnedIn} from '@material-ui/icons';
import {Fingerprint} from '@material-ui/icons';
import {Gavel} from '@material-ui/icons';
import {Group} from '@material-ui/icons';
import {LibraryBooks} from '@material-ui/icons';
import {Dashboard} from '@material-ui/icons';

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import Parallax from "componentsPage/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import NavPills from "components/NavPills/NavPills.js";
import CardBody from "components/Card/CardBody.js";


// import Table as Tables from "components/Table/Table.js";
import styles2 from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";


// import classNames from "classnames";
const useStyles2 = makeStyles(styles2);



export default function ExtendedForms() {
  
  const classes2 = useStyles2();
  
  return (
    <div>
       <Parallax
        image={require("assets/img/sections/bucaramanga.jpg")}
        className={classes2.parallax}
        small
      >
        <div className={classes2.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>                    
                <div className={classes2.brand}>
                <center>    
                  <h1>
                    Trámites
                  </h1>
                </center>
                </div> 
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes2.main, classes2.mainRaised)}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "Registro Civil",
                    tabIcon: AssignmentInd,
                    tabContent: (
                      <span>
                         <center>
                          <h2>REGISTRO CIVIL DE NACIMIENTO</h2>
                        </center>
                        <p>
                        Es un documento indispensable mediante el cual la persona prueba ante la familia y la sociedad su existencia y le permite identificarse, ejercer sus derechos y cumplir sus obligaciones.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Certificado de nacido vivo dado por el médico, centro hospitalario, enfermera o persona que haya ayudado a la madre en el parto. Si se trata de un menor que pertenece a una comunidad indígena, puede servir como documento la autorización indigena.</li>
                              <li>En caso de no contar con el anterior certificado, se requiere declaración bajo juramento de dos testigos que hayan presenciado el nacimiento o hayan tenido conocimiento de él.</li>
                              <li>Documentos de identificación de los padres o de las personas que estén autorizadas por la ley para realizar el registro, como abuelos, tíos o parientes mayores.</li>
                              <li>El registro que se hace después del mes de nacido es extemporáneo y las personas que no cuenten con ninguno de los anteriores requisitos, pueden presentar la partida de bautismo católico o la anotación de origen religioso, si es de otro credo reconocido por el Estado.</li>
                          </ol>                          
                          <b>Tiempo estimado de entrega:</b> El mismo día.
                          <br/>
                          <b>Costo:</b> Sí. Según tarifa notarial.
                          <br/>
                        </p>
                        <br/>

                        <center>
                          <h2>REGISTRO CIVIL DE MATRIMONIO</h2>
                        </center>
                        <p>
                          Es el documento público necesario para probar que una persona está casada. En él se registran los datos de quienes se casan y el acto del matrimonio. Sirve para adquirir derechos y cumplir obligaciones entre sí, ante la familia, las autoridades y la sociedad.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Copia auténtica del registro civil de nacimiento de quienes se casan.</li>
                              <li>Documento de identificación de quienes se casan.</li>
                              <li>Si el matrimonio fue católico. copia auténtica de la partida parroquial.</li>
                              <li>Si el matrimonio fue de otra religión, copia auténtica dada por la confesión religiosa debidamente reconocida por el Estado.</li>
                              <li>Si el matrimonio se realizó ante juez, acta del matrimonio debidamente protocolizada en notaría.</li>
                              <li>Si el matrimonio fue celebrado en el exterior, hay requisitos adicionales que deben consultarse con el notario.</li>
                          </ol>                          
                          <b>Tiempo estimado de entrega:</b> El mismo día.
                          <br/>
                          <b>Costo:</b> La inscripción en el registro no tiene costo. Las copias, según tarifa notarial.
                          <br/>
                        </p>
                        <br/>

                        <center>
                          <h2>REGISTRO CIVIL DE DEFUNCIÓN</h2>
                        </center>
                        <p>
                          Es el documento público que prueba el hecho de la muerte y su causa. Sirve para que la defunción quede anotada en el registro civil de nacimiento y en el registro civil de matrimonio, si lo hubo. También permite el inicio de los trámites de sucesión.
                          Además, la diligencia de defunción la comunica el notario a la Registraduría Nacional del Estado Civil para cancelar la cédula de ciudadanía.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Denuncio de la defunción dentro de los dos días después de haber conocido el fallecimiento. De haber excedido este plazo, debe presentar orden impartida por el inspector de policía, previa solicitud escrita del interesado donde explica las causas del retraso.</li>
                              <li>Certificado médico de defunción o declaración de dos testigos cuando no haya médico en la localidad.</li>
                              <li>Copia de la cédula de ciudadanía de quien registra el fallecimiento.</li>
                              <li>Orden judicial, si la muerte fue violenta o se desconocen los motivos.  Para el caso de muerte presunta por desaparecimiento, sentencia judicial en firme.</li>
                          </ol>                          
                          <b>Tiempo estimado de entrega:</b> El mismo día.
                          <br/>
                          <b>Costo:</b> La inscripción en el registro no tiene costo. Las copias, según tarifa notarial.
                          <br/>
                        </p>
                        <br/>

                        <center>
                          <h2>CAMBIO DE NOMBRE</h2>
                        </center>
                        
                        <p>
                          El cambio de nombre lo podrá hacer la persona mayor de edad voluntariamente o los padres en representación del menor de edad, para suprimir, adicionar o modificar sus nombres o sus apellidos y por regla general, solo puede hacerse una vez por escritura pública ante notario. Los nuevos cambios deben estar orientados a garantizar la identidad entre el nombre y el género o evitar discriminaciones.
                          <br/><b>"ARTICULO 6°. El Articulo 94 del Decreto-Ley 1260 de 1970", quedará así:</b><br/>
                          ARTICULO 94. «Aparte subrayado CONDICIONALMENTE exequible* El propio inscrito podrá disponer, po una sola vez, mediante escritura pública la modificación de registro, para sustituir, rectificar, corregir o adicionar Su nombre, todo con el fin de fijar su identidad personal.
                          <br/><b>Corte Constitucional</b><br/>
                          -La expresión subrayada 'por primera vez' declarada CONDICIONALMENTE EXEQUIBLE por la Corte Constitucional mediante Sentencia C-114-17 de 22 de febrero de 2017 Magistrado Ponente Dr Alejandro Linares Cantillo. •en et entendido de que tal restricción no será aplica ble en aquellos eventos en que exista una justificación constitucional, clara y suficiente, de conformidad con los criterios establecidos en la parte motiva de esta sentencia.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Debe ser realizado directamente por la persona mayor de edad que quiere cambiarse el nombre y si el cambio es para un menor de edad, deben realizarlo sus padres o quien tenga la patria potestad.</li>
                              <li>Presentar documento de identificación y copia auténtica del registro civil de nacimiento que se va a modificar.</li>
                              <li>Si se trata de integrantes de comunidades o pueblos indígenas, deben presentar copia del registro civil de nacimiento y el permiso de la autoridad indigena reconocida por el Estado</li>
                              <li>La mujer casada podrá proceder, por medio de escritura pública, a adicionar o suprimir el apellido del marido precedido de la preposición "de", en los casos en que ella lo hubiere adoptado o hubiere sido establecido por la ley.</li>
                          </ol>
                          El instrumento a que se refiere el presente artículo deberá inscribirse en el correspondiente registro civil del interesado, para lo cual se procederá a la apertura de un nuevo folio. El original y el sustituto llevarán notas de recíproca referencia.                          
                          <br/>
                          <b>Tiempo estimado de entrega:</b> El mismo día.
                          <br/>
                          <b>Costo:</b> Si. Según tarifa notarial. Para miembros de comunidades indígenas, esta diligencia, incluidas las copias auténticas, no tienen Costo.
                          <br/>
                        </p>

                      </span>
                    )
                  },
                  {
                    tabButton: "Matrimonio",
                    tabIcon: Group,
                    tabContent: (
                      <span>
                        <center>
                          <h2>MATRIMONIO</h2>
                        </center>
                        <p>
                          Es un contrato solemne celebrado ante juez o notario, por el cual se unen legalmente un hombre y una mujer o dos personas del mismo sexo, con el fin de vivir juntos, auxiliarse mutuamente y constituir una familia. Por el hecho del matrimonio, nace la sociedad conyugal o sociedad de los bienes adquiridos dentro del matrimonio. La diligencia de matrimonio termina con la firma del notario denominada autorización notarial en la escritura pública.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Solicitud escrita ante el notario por ambos interesados o por intermedio de sus apoderados, con nombres, apellidos, documentos de identidad, lugar de nacimiento, edad, ocupación y domicilio de los contrayentes y nombre de sus padres, indicando que no tienen impedimento legal para celebrar el matrimonio.</li>
                              <li>Copias auténticas de los registros civiles de nacimiento de los contrayentes, que no tengan más de un mes de expedidas. Se requiere la copia completa que incluya todas las anotaciones de estados civiles anteriores para establecer que no hay impedimentos para el nuevo matrimonio. Si alguno de los contrayentes es extranjero, el registro civil no debe tener más de tres meses de expedido.</li>
                              <li>Documentos de identificación de los contrayentes.</li>
                              <li>Los menores de edad deben entregar autorización escrita de sus padres.</li>
                              <li>Si tienen hijos y los contrayentes son el padre y la madre (hijos comunes) deben anexar las copias de los registros civiles de nacimiento de cada uno de los hijos.</li>
                              <li>Para el caso de hijos no comunes que se encuentren bajo su patria potestad o bajo su guarda, deben presentar inventario de los bienes, si los hay, que sean de propiedad de cada hijo.</li>
                              <li>El inventario lo elaborará el curador designado según la ley. Sobre este trámite, consultar al notario.</li>
                              <li>Quienes se casaron por matrimonio religioso, deben llevar la partida eclesiástica del matrimonio católico 0 el certificado de la religión reconocida por el Estado, para que se haga el registro civil en notaría o en la Registraduría.</li>

                          </ol>                          
                          <b>Tiempo estimado de entrega:</b> Consulte con el notario, dado que la celebración del matrimonio depende del cumplimiento de los requisitos legales.
                          <br/>
                          <b>Costo:</b> Sí. Según tarifa notarial.
                          <br/>
                        </p>
                        <center>
                          <h2>DECLARACIÓN DE UNIÓN MARITAL DE HECHO</h2>
                        </center>
                        <p>
                        Es la manifestación ante juez o notario de la existencia de la unión entre dos personas que. sin estar casadas ante la ley, han estado conviviendo de manera permanente y libre durante mínimo 2 años.
                        Esta diligencia termina con la firma del notario en la escritura pública.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          La pareja de compañeros permanentes debe presentar solicitud escrita dirigida al notario en la que manifieste su intención de declarar la unión marital de hecho existente, para lo cual debe expresar que no tiene los impedimentos legales para contraer matrimonio y anexar:
                          <ol>
                              <li>Documentos de identificación.</li>
                              <li>Copias auténticas de los registros civiles de nacimiento que no tengan más de un mes de expedidas, en las que se incluyan todas las anotaciones de estados civiles anteriores.</li>
                              <li>Datos completos (nombres, apellidos, número de documento de identidad, lugar de nacimiento, edad, ocupación y domicilio).  Manifestación de que actúan de común acuerdo.</li>
                              <li>Fecha desde la cual conviven juntos.</li>
                              <li>De ser el caso, identificación de hijos comunes.</li>
                          </ol>
                          Si tienen hijos y los declarantes son el padre y la madre (hijos comunes) deben anexar las copias de los registros civiles de nacimiento de cada uno de los hijos.
                          <br/> 
                          Para el caso de hijos no comunes, que se encuentren bajo su patria potestad o bajo su guarda, deben presentar inventario de los bienes, si los hay, que sean de propiedad de cada hijo. El inventario lo elaborará el curador designado según la ley. Sobre este trámite, consultar al notario.      
                          <br/>                   
                          <b>Tiempo estimado de entrega:</b> Una semana aproximadamente.
                          <br/>
                          <b>Costo:</b> Sí. Según tarifa notarial.
                          <br/>
                        </p>

                        <center>
                          <h2>CAPITULACIONES MATRIMONIALES</h2>
                        </center>
                        <p>
                        Son los acuerdos que hacen las personas que se van a casar (por matrimonio civil o matrimonio religioso) sobre los bienes que tienen o podrán tener, para incluirlos o no en la futura sociedad conyugal que nacerá por el hecho del matrimonio. Las capitulaciones se deben hacer ante notario por escritura pública, antes de contraer matrimonio. Si se hacen después del matrimonio, no sirven. Una vez hechas, las capitulaciones no se pueden modificar. Si el matrimonio no se realiza, las capitulaciones no tienen efecto, ni pueden hacerse cumplir.
                        </p>
                        <p>
                          <b>Requisitos:</b>                    
                          <ol>
                              <li>Documentos de identificación de los interesados.</li>
                              <li>Relación detallada de los bienes (muebles e inmuebles) que las personas que se van a casar aportan al matrimonio y de aquellos bienes que no se incluyen en la sociedad conyugal, con indicación de su valor y los documentos que acreditan su costo, así como una relación de las deudas de cada uno.</li>
                              <li>Recibo de pago del impuesto predial que contenga el avalúo catastral, correspondiente al año en que se celebran las capitulaciones, para determinar el valor de los inmuebles.</li>
                          </ol>
                          <b>Tiempo estimado de entrega:</b> Consulte con el notario, dado que la diligencia de la escritura pública depende de las características particulares de los interesados, de los bienes y del cumplimiento de los requisitos legales.
                          <br/>
                          <b>Costo:</b> Sí. Según tarifa notarial.
                          <br/>
                        </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "Escrituración",
                    tabIcon: LibraryBooks,
                    tabContent: (
                      <span>
                        <center>
                        <h2>ESCRITURACIÓN</h2>
                        </center>
                        <p>
                          Es un documento que contiene la declaración de voluntad de una o varias personas, emitidas ante el notario, para ahcer un determinado contrato o un acto jurídico individual.
                        </p>
                        <p>
                          El proceso de elaboración de una escritura pública tiene cuatro etapas en la notaría:
                          <ol>
                            <li>Recepción de las declaraciones, esot es, de lo que el usuario quiere decir ante el notario.</li>
                            <li>Extensión o transcripció en texto escrito de lo dicho o declarado por el usuario, ante el notario.</li>
                            <li>Lectura, aceptación y otorgamiento del texto que ha leído o ha oido el usuario.</li>
                            <li>Autorización y firma del notario, después de la firma de los interesados y después de cumplidos todos los requisitos del contrato respectivo.</li>
                          </ol>
                        </p>
                        
                        <center>
                        <h2>PROMESA DE COMPRAVENTA DE UN INMUEBLE</h2>
                        </center>
                        <p>
                        Es un documento privado que realizan las partes para celebraren el futuro un contrato por escritura pública. La promesa de compraventa no hace propietario del inmueble al futuro comprador. Sólo se adquiere la propiedad con la escritura pública de compra debidamente registrada en la Oficina de Registro de Instrumentos Públicos.
                        </p>
                        <p>
                          <b>REQUISITOS:</b>
                          <br/>
                          <ol>
                              <li>La persona debe ser mayordeedady por lo tanto está en capacidad de hacerlo.Lapersona debe ser mayordeedady por lo tanto está en capacidad de hacerlo.</li>
                              <li>La promesa constará por escrito y debe contenerla fecha, hora y la notaría donde se firmará la escritura de compraventa.</li>
                              <li>Las personas que firman la promesa de compraventa, deben estar de acuerdo con su contenido.</li>
                              <li>Lo que se promete comprar o vender debe estar permitido por la ley.</li>
                          </ol>                          
                          <b>TIEMPO ESTIMADO DE ENTREGA:</b> El mismo día, si se cumplen todos los requisitos de ley.
                          <br/>
                          <b>COSTO:</b> Sí. Según tarifa notarial.
                          <br/>
                        </p>

                        <center>
                        <h2>COMPRAVENTA DE INMUEBLES</h2>
                        </center>
                        <p>
                        Mediante este contrato una persona se convierte en propietaria de una casa, lote sin construcción, apartamento o una finca, entre otros. Consiste en traspasar a Otra persona uno de esos inmuebles a cambio de un valor en dinero que el comprador le paga al vendedor. Este contrato se hace obligatoriamente por medio de escritura pública, Vendedor y comprador deben cumplir dos etapas:
                        <br/><b>PRIMERA ETAPA EN LA NOTARÍA:</b> Hacer la escritura pública de compraventa, en la que se especifiquen los linderos de la vivienda o de la finca y el precio.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Documentos de identificación de los interesados.</li>
                              <li>El vendedor debe ser el dueño del inmueble y mayor de edad. El comprador también debe ser mayor de edad</li>
                              <li>Si una de las partes es persona menor de edad, deberá comprar o vender por representante legal (padres en ejercicio de la patria potestad, entre otros).</li>
                              <li>Certificados de paz y salvo de impuesto predial que contenga el avalúo catastral y de contribución de valorización, si aplica en el municipio. Si el inmueble está sometido a propiedad horizontal, se necesita también el certificado de paz y salvo por el pago de la administración.</li>
                              <li>Si el vendedor o comprador es una persona jurídica, como una sociedad o un banco, debe presentarse el documento que acredite su existencia y representación legal.</li>
                              <li>Se recomienda que el vendedor, como dueño de la vivienda, presente al notario copia de la escritura pública o título de adquisición, junto con el certificado de libertad y tradición más reciente que le da la Oficina de Registro de Instrumentos Públicos, para poderverificar la historia del inmueble y los linderos.</li>
                              <li>Cuando el apartamento esté en el régimen de propiedad horizontal, se debe presentar copia de la parte del reglamento que se refiere a la propiedad que se vende.</li>
                          </ol>       
                          <b>SEGUNDA ETAPA EN LA OFICINA DE REGISTRO DE INSTRUMENTOS PUBLICOS:</b> Una vez terminada la primera etapa, o sea, la de la escritura pública, el notario entrega copia auténtica a los interesados para llevarla a esta oficina.                   
                          <br/><br/>
                          <b>Tiempo estimado de entrega:</b> Es de mínimo ocho días hábiles, si la escritura es firmada en el mismo momento por vendedor y comprador, para que el notario revise la legalidad del contrato. Si no firman al tiempo, puede superar este plazo. Consulte al notario.
                          <br/>
                          <b>Costo:</b> Sí. Según tarifa notarial.
                          <br/>
                        </p>

                        <center>
                        <h2>PERMUTA DE INMUEBLES</h2>
                        </center>
                        <p>
                        Es uno de los contratos para que una persona se convierta en propietaria de una vivienda, lote sin construcción o de una finca, entre otros bienes. Consiste, por ejemplo, en el intercambio de una vivienda 0 de una finca por casas o cosas y dinero. Para que sea una permuta, el intercambio debe hacerse por un valor mayor en cosas que en dinero. Si el valores mayor en dinero, el contrato será de compraventa.
                        <br/>
                        Para realizar este contrato que se hace por medio de escritura pública, los interesados deben cumplir dos etapas:
                        </p>
                        <p>
                        <b>PRIMERA ETAPA EN LA NOTARÍA:</b> Hacer la escritura pública de permuta, en la que se especifiquen los linderos de los bienes que se intercambian y el precio de los mismos.
                          <br/>
                          <br/>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Documentos de identificación de las personas que hacen el contrato.</li>
                              <li>Estas personas deben ser mayores de edad y por lo tanto están en capacidad de hacerlo.</li>
                              <li>Certificados de paz y salvo por impuesto predial que se paga en la tesorería municipal y que contiene el avalúo catastral. Además, el paz y salvo por contribución de valorización, si aplica en el municipio. Si el inmueble está sometido a propiedad horizontal, se necesita también el certificado de paz y salvo por el pago de cuotas de la administración.</li>
                              <li>Si alguno de quienes hacen el contrato, es persona jurídica, como una sociedad o un banco, debe presentarse el documento que acredite su existencia y representación legal.</li>
                              <li>Se recomienda que se lleve al notario copia de la escritura pública o título de adquisición, junto con el certificado de libertad y tradición más reciente que le ha dado la Oficina de Registro de Instrumentos Públicos.</li>
                              <li>Se sugiere que se presente copia de la parte del reglamento que se refiere a las propiedades que se permutan.</li>
                          </ol>       
                          <b>SEGUNDA ETAPA EN LA OFICINA DE REGISTRO DE INSTRUMENTOS PUBLICOS:</b> Una vez terminada la primera etapa, o sea, la de la escritura pública, el notario entrega copia auténtica a los interesados para llevarla a esta oficina.                   
                          <br/><br/>
                            Para ser propietario de bienes inmuebles se requiere la escritura pública debidamente registrada donde consten la compraventa o la permuta. No olvide este requisito.
                          <br/><br/>
                          <b>Tiempo estimado de entrega:</b> Si firman al tiempo los interesados, es mínimo ocho días hábiles, mientras el notario revisa la legalidad del contrato. firman al tiempo, puede superareste plazo.
                          <br/>
                          <b>Costo:</b> Sí. Según tarifa notarial.
                          <br/>
                          
                        </p>
                       
                      </span>
                    )
                  }, 
                  {
                    tabButton: "Autenticaciones",
                    tabIcon: Fingerprint,
                    tabContent: (
                      <span>
                        <center>
                          <h2>AUTENTICACIÓN</h2>
                        </center>                          
                        <p>
                        Es cuando el notario da testimonio escrito de que las firmas que aparecen en un documento privado fueron 
                        puestas en su presencia, estableciendo la identidad de los firmantes y dando fe del día en que el hecho ocurrió.
                        </p>
                        <p>
                          <b>REQUISITOS:</b>
                          <br/>
                          <ol>
                              <li>Asistir personalmente a la notaría. Puede hacerse también adomicilio.</li>
                              <li>Llevarla cédula de ciudadanía.</li>
                              <li>Llevar el documento que se va a autenticar.</li>
                          </ol>                          
                          <b>TIEMPO ESTIMADO DE ENTREGA:</b> El mismo día.
                          <br/>
                          <b>COSTO:</b> Sí. Según tarifa notarial.
                          <br/>
                        </p>
                        <br/>
                        <center>
                          <h2>RECONOCIMIENTO DE FIRMA Y CONTENIDO</h2>
                        </center>  
                        <p>
                        Quienes hayan firmado un documento privado podrán acudir ante el notario para declarar que las firmas son suyas y que el contenido del documento es cierto. La diligencia concluye con las firmas de los declarantes y del notario. De la veracidad de lo declarado responden los declarantes.
                        </p>
                        <p>
                          <b>REQUISITOS:</b>
                          <br/>
                          <ol>
                              <li>Asistir personalmente a la notaría.</li>
                              <li>Llevarla cédula de ciudadanía.</li>
                              <li>Llevar el documento que se va a reconocer.</li>
                          </ol>                          
                          <b>TIEMPO ESTIMADO DE ENTREGA:</b> El mismo día.
                          <br/>
                          <b>COSTO:</b> Sí. Según tarifa notarial.
                          <br/>
                        </p>

                        <center>
                        <h2>FIRMA A RUEGO- PERSONAS QUE NO SABEN O NO PUEDEN FIRMAR</h2>
                        </center>
                        <p>
                          Cuando se trate de personas que no sepan o no puedan firmar, en la diligencia se leerá en voz alta el documento que será firmado por un testigo presentado por el interesado. De esta diligencia, denominada 'firma a ruego", el notario dejará constancia en el mismo documento.
                          Para las personas con discapacidad visual o que no sepan leer y escribir, el notario leerá en voz alta el documento. Para las personas sordas, ellos mismos leerán el documento y expresarán su aprobación.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <ol>
                            <li>Asistir personalmente a la notaría. Excepcionalmente, puede hacerse fuera del despacho notarial.</li>
                            <li>Llevar la cédula de ciudadanía.</li>
                            <li>Llevar el documento privado que se va a firmar.</li>
                            <li>Llevar el testigo cuando así se requiera.</li>
                          </ol>
                        </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "DECLARACIONES",
                    tabIcon: AssignmentTurnedIn,
                    tabContent: (
                      <span>
                        <center>
                          <h2>EXTRA PROCESO O DECLARACIONES BAJO GRAVEDAD DE JURAMENTO</h2>
                        </center>
                        <p>
                          Son declaraciones verbales o escritas presentadas ante el notario en las que se asegura bajo juramento la existencia de determinados hechos. La declaración verbal se transcribe en la notaría y la escrita la puede llevar el interesado.
                        </p>
                        <p>
                          <b>REQUISITOS:</b>
                          <br/>
                          <ol>
                              <li>Asistir personalmente a la notaría.</li>
                              <li>Llevarla cédula de ciudadanía.</li>
                              <li>Llevarla declaración escrita para revisión del notario o darla versión verbalmente.</li>
                          </ol>                          
                          <b>TIEMPO ESTIMADO DE ENTREGA:</b> El mismo día.
                          <br/>
                          <b>COSTO:</b> Si. Según tarifa notarial. Las madres que acrediten su condición de cabeza de familia no tienen que pagar.
                          <br/>
                        </p>

                        <center>
                          <h2>PODER</h2>
                        </center>
                        <p>
                          Es el acto mediante el cual una persona autoriza a otra para que la represente en la realización de un contrato o acto jurídico. En cada poder se debe especificar el propósito para el cual se otorga, es decir, debe contener la relación precisa de las facultades que se dan y para qué se dan a la persona que recibe el poder (apoderada). El poder puede constar en documento privado autenticado en su firma y reconocido en su contenido o en escritura pública.
                        </p>
                        <p>
                          <b>REQUISITOS:</b>
                          <br/>
                          <ol>
                              <li>La persona que va a darel poderdebe asistira la notaría.</li>
                              <li>Llevarla cédula de ciudadanía.</li>
                              <li>Llevar el documento escrito para que el notario lo autentique y reconozca.</li>
                          </ol>                          
                          <b>TIEMPO ESTIMADO DE ENTREGA:</b> El mismo día.
                          <br/>
                          <b>COSTO:</b> Sí. Según tarifa notarial.
                          <br/>
                        </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "Sucesiones",
                    tabIcon: Gavel,
                    tabContent: (
                      <span>
                        <center>
                          <h2>SUCESIÓN DE BIENES POR CAUSA DE MUERTE</h2>
                        </center>
                        <p>
                          Es la que se hace para repartir los bienes del difunto, restando de ellos las deudas que tenía. Esta repartición se hace, una vez pagados los impuestos respectivos, entre los herederos del difunto, los acreedores y las demás personas que tengan interés en la misma según la ley. Este acto tiene un trámite especial, incluyendo edictos para informar al público sobre el inicio de la sucesión. El acto de la sucesión termina con la firma del notario en la escritura pública.
                        </p>
                        <p>
                          Todos los interesados deben estar de acuerdo desde el comienzo hasta el final de la sucesión. En el caso de surgir un desacuerdo, la sucesión pasa a juzgado.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Tanto el viudo o viuda, como todos los hijos y demás herederos e interesados, tienen que dar poder especial a un abogado para que adelante la diligencia. Este poder puede hacerse por escritura pública o por documento privado, en cuyo caso necesita de una autenticación especial llamada "reconocimiento de firma y contenido" y debe incluir la afirmación bajo juramento de la no existencia de otros herederos o interesados.</li>
                              <li>Solicitud escrita por el abogado de la sucesión, junto con el inventario de bienes, la estimación de su valor y la repartición de los bienes entre los solicitantes.</li>
                              <li>Si el difunto dejó bienes inmuebles se deben presentar los certificados de paz y salvo por impuesto predial. Si el inmueble es un apartamento o un local en un edificio, se necesita también el certificado de paz y salvo por el pago de la administración.</li>
                              <li>Si en el inventario se incluyen deudas, debe presentarse el documento que lo pruebe.</li>
                              <li>Copia auténtica del registro civil de defunción de la persona que falleció.</li>
                              <li>Si el difunto fue casado se requiere presentar copia auténtica del registro civil de matrimonio, para liquidar primero la sociedad conyugal y entregarle al viudo o viuda lo que le corresponde. En caso de unión marital de hecho, se debe presentar la declaración respectiva.</li>
                              <li>Si hubo hijos y todos están de acuerdo en llevar la sucesión ante notario, deben presentar sus registros civiles de nacimiento, para probar su calidad de herederos. Todos heredan por partes iguales si no hay testamento.</li>
                              <li>Si el difunto hizo testamento abierto, debe presentarse copia auténtica de la escritura pública. Si hizo testamento cerrado o secreto, debe presentar copia auténtica de la escritura pública donde consta la diligencia de apertura.</li>
                          </ol>                          
                          <b>Tiempo estimado de entrega:</b> Consulte con el notario, dado que el trámite de la sucesión tiene características particulares según os solicitantes, los bienes que se reparten y lo ordenado en el testamento, si lo hubo.
                          <br/>
                          <b>Costo:</b> Si. Según tarifa notarial.
                          <br/>
                        </p>

                        <center>
                          <h2>TESTAMENTO</h2>
                        </center>
                        <p>
                          Es un acto por el cual una persona dispone de todos o de una parte de sus bienes, para que así sea tenido en cuenta después de su muerte en la sucesión. La distribución de los bienes en el testamento debe hacerse de acuerdo con la ley. Mientras viva la persona, puede cambiar el testamento.
                          <br/>En el testamento la persona también puede reconocer un hijo y ese reconocimiento no puede cambiarse.
                          <br/>Las dos clases de testamento más frecuentes son abierto y cerrado o secreto y se hace por escritura pública.
                        </p>
                        <p>
                          <b>REQUISITOS PARA EL TESTAMENTO ABIERTO:</b>
                          <br/>
                          <ol>
                              <li>La persona que va a repartir sus bienes debe ser mayor de edad y por lo tanto, está en capacidad para hacerlo. El notario verificará esta condición.</li>
                              <li>Documento de identificación de la persona que va a hacer el testamento.</li>
                              <li>Tres testigos y sus documentos de identificación.</li>
                              <li>Se sugiere que la persona que va a repartir sus bienes deje por escrito sus disposiciones, a partir de las cuales el notario realizará la escritura pública.</li>
                              <li>El acto termina con la firma de la persona que hace el testamento, la de los tres testigos y la del notario.</li>
                          </ol>    
                          <b>REQUISITOS PARA EL TESTAMENTO CERRADO:</b>
                          <br/>
                          <ol>
                              <li>La persona que va a repartir sus bienes debe ser mayor de edad y por lo tanto está en capacidad para hacerlo. El notario verificará esta condición.</li>
                              <li>Documento de identificación de la persona que hace el testamento</li>
                              <li>Cinco testigos y sus documentos de identificación.</li>
                              <li>Sobre cerrado presentado directamente por la persona que hace el testamento, con la declaración escrita de que contiene su última voluntad. El sobre debe estar marcado con la palabra "testamento"</li>
                              <li>El acto termina con la firma de la persona que hace el testamento, las de los cinco testigos y la del notario. El notario guardará el testamento.</li>
                              <li>Cuando muera la persona que hizo el testamento cerrado, los interesados deben solicitaral notario su apertura, presentando:
                                  Documentos de identificación.
                                  Solicitud escrita y prueba de su relación con la persona que hizo el testamento cerrado. Si el interesado es hijo del fallecido, debe presentar copia auténtica del registro civil de nacimiento.
                                  Registro civil de defunción de la persona que hizo el testamento cerrado. Copia auténtica de la escritura pública que se realizó a partir de la entrega del sobre cerrado al notario.</li>
                          </ol> 
                          Los testigos, tanto para el testamento abierto como para el testamento cerrado, tienen que cumplir ciertos requisitos de la ley, entre otros. No pueden ser menores de 1 5 años, empleados de la notaría, hermanos, tíos o primos de la persona que hizo el testamento o del notario. Consulte al notario para ampliar esta información y evitar que el testamento sea inválido.                         
                          <br/>
                          <br/>
                          <b>Tiempo estimado de entrega:</b> Consulte con el notario, dado que la celebración de este acto depende de la revisión que él hace sobre la legalidad del testamento y los testigos.
                          <br/>
                          <b>Costo:</b> Si. Según tarifa notarial.
                          <br/>
                        </p>
                      </span>
                    )
                  } ,
                  {
                    tabButton: "Otros",
                    tabIcon: Dashboard,
                    tabContent: (
                      <span>
                        <center>
                          <h2>DONACIÓN</h2>
                        </center>
                        <p>
                        Es uno de los contratos cuyo fin es que una persona se convierta en dueña de una vivienda, lote, finca, mueble o de una suma de dinero. cuando otra persona le regala una propiedad 0 parte de ella, y el que la recibe la acepta.
                        </p>
                        <p>
                        Solo se hace por escritura pública, cuando se recibe un inmueble o parte de él o cuando lo que se recibe vale más de 50 salarios mínimos mensuales.
                        </p>
                        <p>
                        Cuando se va a hacer una donación que valga más de 50 salarios mínimos mensuales, hay que cumplir primero un requisito que se llama "insinuación de donación" , el cual se realiza en escritura pública en una notaría y en esa misma escritura se puede realizar la donación.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>El que va a donar o regalar debe ser mayor de edad y por lo tanto está en capacidad de hacerlo. Si se trata de una donación a un menor, lo representa el padre o la madre, ambos o quien tiene la guarda.</li>
                              <li>Documentos de identificación de los interesados.</li>
                              <li>Solicitud por escrito de quien hace la donación y de quien la recibe, ante el notario de la ciudad o municipio en donde vive el donante, con certificado del valor comercial de la propiedad que se va a donar, si es un inmueble. Para más información consulte al notario.</li>
                              <li>Título de propiedad del bien que se va a donar, si es un inmueble.</li>
                              <li>Certificados de paz y salvo por impuesto predial que contenga el avalúo catastral y por contribución de valorización, si aplica en el municipio. Si es un apartamento y forma parte de una propiedad horizontal, se necesita también el certificado de paz y salvo por el pago de la administración.</li>
                              <li>Si alguno de los interesados es persona jurídica. como una sociedad, consulte al notario.</li>
                              <li>La persona que hace la donación debe demostrar que queda con recursos suficientes para vivir y atender sus obligaciones.</li>
                              <li>Se recomienda llevar al notario copia de la escritura pública o título de adquisición del inmueble que se va a regalar junto con el certificado de libertad y tradición más reciente que le ha dado la Oficina de Registro de Instrumentos Públicos.</li>
                          </ol>  
                          Una vez cumplidos los requisitos anteriores y se tenga la escritura pública, el notario entrega copia auténtica a los interesados para llevarla a la Oficina de Registro de Instrumentos Públicos.                        
                          <br/>
                          <br/>
                          <b>REQUISITOS PARA DONACIÓN DE BIENES QUE VALGAN MÁS DE 50 SALARIOS MÍNIMOS MENSUALES:</b> Consulteal notario.
                          <br/>
                          <br/>
                          <b>Tiempo estimado de entrega:</b> Depende del cumplimiento de los requisitos. Consulte al notario.
                          <br/>
                          <b>Costo:</b> Si. Según tarifa notarial.
                          <br/>
                        </p>

                        <center>
                        <h2>CONSTITUCIÓN DE HIPOTECA</h2>
                        </center>
                        <p>
                        Hay hipoteca cuando una persona, un banco o una entidad financiera (llamada acreedora) le presta a otra persona una suma de dinero y esta última (llamada deudora) asegura el pago, garantizándolo con un inmueble de su propiedad. Si el deudor incumple con los pagos (capital e intereses), el inmueble podrá ser embargado y rematado por un juez para pagarle a quien prestó el dinero. La hipoteca siempre se hace por escritura pública.  
                        <br/>
                        Para hacer la hipoteca los interesados deben cumplir dos etapas:
                        </p>
                        <p>
                        <b>PRIMERA ETAPA EN LA NOTARÍA:</b> Firmar (otorgar) la escritura pública de hipoteca, en la que aparece la descripción del inmueble que se da en garantía y la manifestación de que ese inmueble queda hipotecado a favor del acreedor.
                          <br/>
                          <br/>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Los interesados deben ser mayores de edad y por lo tanto, están en capacidad de hacerlo.</li>
                              <li>Documentos de identificación de los interesados. Si alguno de los interesados es persona jurídica, debe presentarse el documento que acredite su existencia y representación legal.</li>
                              <li>Certificados de paz y salvo del impuesto predial según el valor catastral y de la contribución de valorización del inmueble que se hipoteca, si aplica en el municipio.</li>
                              <li>Certificado de libertad y tradición y copia de la escritura pública que demuestre que quien hipoteca es el dueño del bien.</li>
                              <li>Se puede dar un poder para hacer una hipoteca. Consulte al notario.</li>
                              <li>Casi siempre, la hipoteca se hace en la misma escritura de compraventa, pero puede hacerse por separado.</li>
                          </ol>       
                          <b>SEGUNDA ETAPA EN LA OFICINA DE REGISTRO DE INSTRUMENTOS PUBLICOS:</b> Una vez terminada la primera etapa, o sea, la de la escritura pública, el notario entrega copia auténtica a los interesados para llevarla a esa oficina, dentro de los noventa días hábiles siguientes a la fecha de la escritura. Olvidar este plazo, significa volver a iniciar el trámite de la hipoteca.
                          <br/><br/>
                          <b>Tiempo estimado de entrega:</b> Si firman al tiempo los interesados, es de mínimo ocho días hábiles mientras el notario revisa la legalidad del contrato. Si no firman al tiempo, puede superar este plazo.
                          <br/>
                          <b>Costo:</b> Sí. Según tarifa notarial.
                          <br/>
                          
                        </p>

                        <center>
                        <h2>CANCELACIÓN DE HIPOTECA</h2>
                        </center>
                        <p>
                        Para cancelar una hipoteca, el dueño del bien hipotecado debe pagar la totalidad de la deuda al acreedor. Una vez ocurrido el pago, quien prestó el dinero (el acreedor) debe ir a la notaria para declarar que la deuda está pagada y que por ello cancela la hipoteca. Este acto de cancelación se hace también en escritura pública. El notario entrega un certificado que debe llevar el dueño del inmueble a la Oficina de Registro para que su bien quede sin hipoteca.
                        <br/>
                        <br/>
                        <b>Tiempo estimado de entrega:</b> Si firman al tiempo los interesados, es de mínimo ocho días hábiles mientras el notario revisa la legalidad del contrato. Si no firman al tiempo, puede superar este plazo.
                        <br/>
                        <b>Costo:</b> Sí. Según tarifa notarial.
                        <br/>                        
                        </p>


                        <center>
                          <h2>AFECTACIÓN A VIVIENDA FAMILIAR</h2>
                        </center>
                        <p>
                        Esta protección la ordena la ley sobre la vivienda que habita la pareja casada o en unión marital durante al menos dos años y se llama comúnmente, la ly de la doble firma,  porque para vender el inmueble protegido se requiere la firma de los dos.
                        <br/>
                        Además, solo puede ser embargado por el acreedor que figura en la escritura de hipoteca(Entidad financiera que realizó el préstamo para la adquisición de la vivienda, mientras haya deuda).
                        </p>
                        <p>
                        Los cónyuges o compañeros permanentes pueden decidir no someter su vivienda a esta protección. La afectación a vivienda familiar puede hacerse al momento de comprar la vivienda o tiempo después.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Documentos de identificación de los cónyuges o compañeros permanentes.</li>
                              <li>Si el dueño de la vivienda es uno de los cónyuges, para protegerla se necesita que firme el otro cónyuge o compañero permanente. </li>
                              <li>Certificado de libertad de la vivienda.</li>
                              <li>Para cancelar la afectación a vivienda familiar, se deben cumplir estos requisitos:</li>
                              <li>Documentos de identificación de los cónyuges o compañeros permanentes.</li>
                              <li>Escritura pública donde aparece la protección de la vivienda.</li>
                              <li>Defunción.</li>
                          </ol> 
                          <b>Tiempo estimado de entrega:</b> Consulte con el notario, dado que el acto depende de la revisión que hace éste. llamada "control de legalidad"
                          <br/>
                          <b>Costo:</b> Si. Según tarifa notarial.
                          <br/>
                        </p>

                        <center>
                          <h2>PATRIMONIO DE FAMILIA INEMBARGABLE</h2>
                        </center>
                        <p>
                        Es una clase especial de protección de la vivienda de una familia, que impide el embargo que pueda hacerle un acreedor al dueño de la propiedad. Además, evita que el propietario ponga en peligro el inmueble de la familia por un mal negocio. Si en el momento de la compra no se hizo esta protección, puede hacerse en cualquier momento. Solo puede ser embargado por el acreedor que figura en la escritura de hipoteca, esto es, por la entidad financiera o por la persona que realizó el préstamo para la adquisición de la vivienda, mientras haya deuda en mora.
                        <br/>
                        Cuando es una vivienda de interés social, es obligatorio por ley y se debe hacer en la misma escritura pública de compra de la vivienda.
                        </p>
                        <p>
                          <b>Requisitos:</b>
                          <br/>
                          <ol>
                              <li>Documentos de identificación de los cónyuges o compañeros permanentes.</li>
                              <li>Certificado de la Oficina de Registro de Instrumentos Públicos sobre la vivienda</li>
                              <li>Copia auténtica del registro civil de los hijos menores que se benefician de la protección. Se beneficia también de esta protección el cónyuge o compañero permanente que no es dueño de la vivienda.</li>
                              <li>Una vez hecha la escritura pública de patrimonio de familia inembargable, notario entrega la copia auténtica para llevarla a la Oficina de Registro d Instrumentos Públicos, dentro de los noventa días siguientes a la fecha de la escritura. Olvidar este plazo, significa volver a iniciar el trámite.</li>   
                          </ol> 
                          Esta protección se puede cancelar, en la mayoría de los casos, cuando los hijos menores cumplen dieciocho años. Consulte con el notario.
                          <br/>
                          <br/>
                          <b>Tiempo estimado de entrega:</b> Mínimo ocho días hábiles mientras el notario revisa la legalidad del contrato.
                          <br/>
                          <b>Costo:</b> Si. Según tarifa notarial.
                          <br/>
                        </p>
                      </span>
                    )
                  }                  
                ]}
              />
            </GridItem>
          </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      </div>
    </div>
  );
}
