import React from "react";
import store from './store'
import {newRoutes} from "./newRoutes.js";
import { Provider } from 'react-redux'
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Route, Routes, Navigate, createBrowserRouter, RouterProvider, redirect  } from "react-router-dom";


import PageLayout from "layouts/Page.js";
import Home from "views/PresentationPage/PresentationPage.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
import 'assets/css/material-dashboard.css';

const hist = createBrowserHistory();

const router = createBrowserRouter(newRoutes);

ReactDOM.render(
    <Provider store={store}>
      <RouterProvider router={router}>
      </RouterProvider>
    </Provider>,
  document.getElementById("root")
);


