import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
const useStyles = makeStyles(aboutUsStyle);
// import Tramites from "/src/views/Molda/Tramites.js";
// core components

// sections for this page
// import Politica from "./Informacion/Informacion.js";


function PlaneacionPresupuesto() {
    const classes = useStyles();
    // const [iconTabs, setIconTabs] = React.useState("1");
    // const [Modal3, setModal3] = React.useState(false);
    // const [tooltipOpen, setTooltipOpen] = useState(false);
    // const toggle = () => setTooltipOpen(!tooltipOpen);

    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);
            new Rellax(".rellax-header");
            new Rellax(".rellax-text");
        }

        // we need to add a script for the github buttons
        let script = document.createElement("script");
        script.src = "https://buttons.github.io/buttons.js";
        script.id = "github-buttons-script-id";
        document.body.appendChild(script);

        return function cleanup() {
            document.body.classList.remove("tramites-page");
            document.body.classList.remove("sidebar-collapse");

            // we need to remove the script when we change the page
            script.parentNode.removeChild(script);
        };
    });
    return (
        <div>
            <Parallax image={require("../../assets/img/sections/bucaramanga.jpg")} filter="dark" small>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem
                            md={8}
                            sm={8}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto,
                                classes.textCenter
                            )}
                        >
                            <h1 className={classes.title}>Planeación y presupuesto participativo</h1>
                            <h4>

                            </h4>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container} >
                    <h4>Planeación y presupuesto participativo</h4>
                </div>
            </div>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <Row className="justify-content-center">
                    <Col md="10">
                        <Card>
                            <CardBody>
                                <div className="title">
                                    <h1 className="text-justify title" style={{ color: "#031425" }}>
                                        <br></br>
                                        <br></br>
                                    </h1>
                                    <h3 className="text-justify title" style={{ color: "#031425" }}>
                                        <b>Planeación y presupuesto participativo</b> <br></br>
                                    </h3>

                                </div>
                                <p className="text-justify" style={{ fontSize: "14px", color: "#68696F", fontFamily: "serif" }}>
                                    <b>La planeación participativa</b> es entendida como el mecanismo mediante el cual la sociedad civil decide el
                                    rumbo de las políticas, planes, programas, proyectos o trámites de acuerdo con sus necesidades. La participación
                                    puede darse en la delimitación de los resultados e impactos esperados y en la definición de productos y de actividades.*
                                    <br></br>
                                    <br></br>
                                    <a href="https://www.funcionpublica.gov.co/inicio?p_p_state=maximized&p_p_mode=view&saveLastPath=false&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Flogin&p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&_com_liferay_login_web_portlet_LoginPortlet_redirect=%2Fdocuments%2F">
                                        <b style={{ color: '#045FB4' }}>
                                            * Departamento Administrativo de la Función Pública (2021). Lineamientos para publicar información en el menú participa sobre participación ciudadana en la gestión pública. Obtenido de: https://www.funcionpublica.gov.co/documents/
                                        </b>
                                    </a>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>

    );

}

export default PlaneacionPresupuesto;