/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; 

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import SectionContact from "views/AboutUsPage/Sections/SectionContact.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function PoliticasDatosPersonales() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/sections/bucaramanga.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Política de tratamiento de datos personales</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ paddingTop: '50px', paddingBottom: '30px' }}>
          <h4>Política de tratamiento de datos personales</h4>
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ paddingTop: '50px', paddingBottom: '30px' }}>
          <center className="mt-5">
            <a href={"https://drive.google.com/file/d/1uSV4NKFqZTLYHZTikGN2axpEdILVgXfn/view?usp=sharing"} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
              <img
                alt="PROTECCION DE DATOS PERSONALES"
                width="80px"
                src={require("assets/img/sections/pdf.jpg")}
              ></img>
              <h6 className="my-3" >PROTECCION DE DATOS PERSONALES.</h6>
            </a>
          </center>
        </div>
      </div>
      <div className="card">
        <div className="card-img-top d-flex">
          <Document
            className="w-200 d-flex justify-content-center align-items-center overflow-hidden"
            file="CODIGO-DE-TRATAMIENTO-DE-DATOS-PERSONALES-5.pdf"
            error={<object className="w-100" type="application/pdf" title={"Datos Personales"} aria-label={"Datos Personales"} ></object>}
            loading={<div className="spinner-border" role="status" />}
          >
            <Page pageNumber={1} scale={0.5} />
          </Document>
        </div>
      </div>
    </div>
  );
}
