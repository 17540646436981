/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import {FilterNone} from '@material-ui/icons';
import {Apps} from "@material-ui/icons";
import {Dns} from "@material-ui/icons";
import {AttachMoney} from '@material-ui/icons';
import {People} from "@material-ui/icons";
import {Assignment} from "@material-ui/icons";
import {Fingerprint} from "@material-ui/icons";
import {LineStyle} from "@material-ui/icons";
import {Menu} from "@material-ui/icons";
import {ViewQuilt} from "@material-ui/icons";
import {Business} from "@material-ui/icons";
import {LocationOn} from "@material-ui/icons";

// core components
import CustomDropdown from "componentsPage/CustomDropdown/CustomDropdown.js";
import Button from "componentsPage/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="SERVICIOS NOTARIALES"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Fingerprint}
          dropdownList={[
            <Link to="/page/Cotizador2" className={classes.dropdownLink}>
              <LineStyle className={classes.dropdownIcons} /> Cotizador
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="CONÓCENOS"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Assignment}
          dropdownList={[
            <Link
              to="/page/MisionVision"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "headers")}
            >
              <Dns className={classes.dropdownIcons} /> Misión y Visión
            </Link>,
            <Link
              to="/page/Procesos"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "contacts")}
            >
              <People className={classes.dropdownIcons} /> Procesos y Procedimientos
            </Link>,
            <Link
              to="/page/Organigrama"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "projects")}
            >
              <Apps className={classes.dropdownIcons} /> Organigrama
            </Link>,
            <Link
              to="/page/Notario"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "projects")}
            >
              <People className={classes.dropdownIcons} /> Notario
            </Link>
          ]}
        />
      </ListItem>
      <CustomDropdown
        noLiPadding
        navDropdown
        hoverColor={dropdownHoverColor}
        buttonText="TRAMITES"
        buttonProps={{
          className: classes.navLink,
          color: "transparent"
        }}

        buttonIcon={ViewQuilt}
        dropdownList={[
          <Link
            to="/page/Tramites"
            className={classes.dropdownLink}
            onClick={e => smoothScroll(e, "headers")}
          >
            <Dns className={classes.dropdownIcons} /> Información
          </Link>,
          <Link
            to="/page/Tramites"
            className={classes.dropdownLink}
            onClick={e => smoothScroll(e, "features")}
          >
            <Business className={classes.dropdownIcons} />Servicios
          </Link>,
          <Link
            to="/page/Tarifas"
            className={classes.dropdownLink}
            onClick={e => smoothScroll(e, "contacts")}
          >

            <AttachMoney className={classes.dropdownIcons} />Tarifas
          </Link>,
        ]}
      />
      <ListItem className={classes.listItem}>
        <Button
          href="/page/home#contacts"
          className={classes.navLink}
          // onClick={e => e.preventDefault()}
          color="transparent"
        >
          <LocationOn className={classes.icons} />CONTÁCTENOS
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/page/transparencia/0"
          className={classes.navLink}
          // onClick={e => e.preventDefault()}
          color="transparent"
        >
          <FilterNone className={classes.icons} />TRANSPARENCIA
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/page/participa"
          className={classes.navLink}
          // onClick={e => e.preventDefault()}
          color="transparent"
        >
          <Menu className={classes.icons} />PARTICIPA
        </Button>
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
