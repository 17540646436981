/*eslint-disable*/
import React from "react";
import { Link, useNavigate , useLocation, useParams } from "react-router-dom";
// @material-ui/core components
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import {Close} from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import './Transparencia.css'
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import {
  Card,
  CardBody,
} from "reactstrap";

const useStyles2 = makeStyles(aboutUsStyle);


const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SectionTransparencia() {
  let navigate = useNavigate();

  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [modal3, setModal3] = React.useState(false);
  const [modal4, setModal4] = React.useState(false);
  const [modal5, setModal5] = React.useState(false);
  const [modal6, setModal6] = React.useState(false);
  const [modal7, setModal7] = React.useState(false);
  const [modal8, setModal8] = React.useState(false);
  const [modal9, setModal9] = React.useState(false);
  const [modal10, setModal10] = React.useState(false);
  const [modal11, setModal11] = React.useState(false);
  const [mecanismosContacto, setmecanismosContacto] = React.useState(false);
  const [informacionInteres, setinformacionInteres] = React.useState(false);
  const [talentoHumano, setTalentoHumano] = React.useState(false);
  const [normativa, setnormativa] = React.useState(false);
  const [mecanismoControl, setmecanismoControl] = React.useState(false);
  const [tramites, setTramites] = React.useState(false);
  const [instrumentos, setinstrumentos] = React.useState(false);
  const [transparencia, settransparencia] = React.useState(false);
  const [planeacion, setPlaneacion] = React.useState(false);
  const [web, setweb] = React.useState(false);
  const [proteccion, setproteccion] = React.useState(false);
  const [activePill, setActivePill] = React.useState("1");
  const [checked, setChecked] = React.useState([]);
  const [classicModal, setClassicModal] = React.useState(false);
  const [anchorElBottom, setAnchorElBottom] = React.useState(null);
  const [prueba, setprueba] = React.useState(false);
  let { modalNombre } = useParams();

  const handleModal1 = (value) => {
    if (value) {
     navigate('/page/transparencia/sitioEnConstruccion');
      setModal1(true);
    } else {
     navigate('/page/transparencia/informacionPublica');
      setModal1(false);
    }
  }

  const handleModal2 = (value) => {
    if (value) {
      navigate('/page/transparencia/directorioEntidades' );
      setModal2(true);
    } else {
      navigate('/page/transparencia/informacionPublica' );
      setModal2(false);
    }
  }


  const handleModal4 = (value) => {
    if (value) {
      navigate('/page/transparencia/funcionesYdeberes' );
      setModal4(true);
    } else {
      navigate('/page/transparencia/informacionPublica' );
      setModal4(false);
    }
  }

  const handleModal5 = (value) => {
    if (value) {
      navigate('/page/transparencia/directorioAgremiaciones' );
      setModal5(true);
    } else {
      navigate('/page/transparencia/informacionPublica' );
      setModal5(false);
    }
  }

  const handleModal6 = (value) => {
    if (value) {
      navigate('/page/transparencia/informesGestion' );
      setModal6(true);
    } else {
      navigate('/page/transparencia/informacionPublica' );
      setModal6(false);
    }
  }

  const handleModal7 = (value) => {
    if (value) {
      navigate('/page/transparencia/entesYautoridades' );
      setModal7(true);
    } else {
      navigate('/page/transparencia/informacionPublica' );
      setModal7(false);
    }
  }

  React.useEffect(() => {

    if (modalNombre) {
      switch (modalNombre) {
        case 'sitioEnConstruccion':
          setModal1(true);
          cerrar1();
          break;
        case 'directorioEntidades':
          setModal2(true);
          cerrar1();
          setmecanismosContacto(true)
          break;
        case 'funcionesYdeberes':
          setModal4(true);
          cerrar1();
          setmecanismosContacto(true)
          break;
        case 'directorioAgremiaciones':
          setModal5(true);
          cerrar1();
          setmecanismosContacto(true);
          break;
        case 'informesGestion':
          setModal6(true);
          cerrar1();
          setTalentoHumano(true);
          break;
        case 'entesYautoridades':
          setModal7(true);
          cerrar1();
          setmecanismosContacto(true);
          break;
        case '0':
          navigate('/page/transparencia/informacionPublica' )
          break;
        default:
          break;
      }
    }

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let modal

    if (urlParams.has('modal')) {
      modal = urlParams.get('modal')
      switch (modal) {
        case '2.1': setModal1(true); break;
        case '2.4': setModal2(true); break;
        case '2.5': setModal3(true); break;
        case '3.2': setModal4(true); break;
        case '3.7': setModal5(true); break;
        case '7.1': setModal6(true); break;
        case '7.4': setModal7(true); break;
        case '10.10': setModal8(true); break;
        case '11.1': setModal9(true); break;
        case '7.5': setModal10(true); break;
        case '13.1.5': setModal11(true); break;
      }

    }
  }, [])


  const cerrar1 = () => {
    setmecanismosContacto(false);
    setinformacionInteres(false);
    setTalentoHumano(false);
    setnormativa(false);
    setmecanismoControl(false);
    setTramites(false);
    setinstrumentos(false);
    settransparencia(false);
    setweb(false);
    setproteccion(false);
  };

  const classes = useStyles();
  const classes2 = useStyles2();
  return (
    <div>
      <Parallax
        image={require("assets/img/sections/bucaramanga.jpg")}
        filter="dark"
        small
      >
        <div className={classes2.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes2.mlAuto,
                classes2.mrAuto,
                classes2.textCenter
              )}
            >
              <h1 className={classes2.title}>Transparencia</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes2.main, classes2.mainRaised)}>
        <div className={classes2.container} style={{ paddingBottom: "30px" }}>
          <div className={classes2.title}>
            <h2>Transparencia y acceso a información pública</h2>
          </div>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "center" }}
            >
              <button
                className="btn btn-primary btn-block"
                type="button"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
                style={{ padding: "23px 11px", backgroundColor: "#f44336" }}
                onClick={() => {
                  cerrar1(), setmecanismosContacto(true);
                }}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <h5 style={{ fontWeight: 500 }}>
                      1. INFORMACIÓN DE LA ENTIDAD.
                    </h5>
                  </GridItem>
                </GridContainer>
              </button>
              {mecanismosContacto && (
                <GridContainer id="FilaI">
                  <GridItem xs={12} md={12} style={{ textAlign: "left" }}>
                    <div className="collapse" id="collapseExample">
                      <div className="card card-body">
                        <GridContainer>
                          <GridItem className="col-6">
                            <ul className="list-group list-group-flush">
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.1. Misión, Visión, Funciones y deberes.
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/MisionVision"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.1.a. Misión y Visión</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  onClick={() => handleModal4(true)}
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.1.b. Funciones y Deberes</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.2. Estructura orgánica - organigrama
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/Organigrama"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.2.a. Organigrama</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.3. Mapas y cartas descriptivas de los procesos
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/Procesos"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.3.a. Mapas y cartas descriptivas de los procesos</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.4. Directorio Institucional incluyendo sedes, oficinas, sucursales, o regionales, y dependencias.
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/home#contacts"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.4.a. Información de contacto</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/home#contacts"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.4.b. Ubicación física.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/home#contacts"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.4.c. Dirección y municipio</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/home#contacts"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.4.d. Horarios y días de atención al público.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/home#contacts"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.4.e. Datos de contacto específicos de las áreas de contacto o dependencias</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.5 Directorio de servidores públicos, empleados o contratistas.
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  onClick={() => handleModal2(true)}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.5.1. Listado de entidades que integran el sector.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.6. Directorio de entidades.
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  onClick={() => handleModal1(true)}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.6.1. Directorio de entidades.</span>
                                </a>
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem className="col-6">
                            <ul
                              className="list-group list-group-flush"
                              style={{
                                fontWeight: 500,
                                fontFamily: "'Noto Sans SC', sans-serif"
                              }}
                            >
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.7. Directorio de agremiaciones, asociaciones y otros grupos de interés.
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  onClick={() => handleModal5(true)}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.7.1. Directorio de agremiaciones, asociaciones y otros grupos de interés.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.8 Servicio al público, normas, formularios y protocolos de atención.
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  onClick={() => handleModal1(true)}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.8.1. Servicio al público, normas, formularios y protocolos de atención.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/Normativa"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.8.2. Normas.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/pqrs"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.8.3. Formularios.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/home#contacts"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.8.4. Protocolos de Atención.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.9 Procedimientos que se siguen para tomar decisiones en las diferentes áreas.
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/TomaDecisiones"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.9.1 Procedimientos que se siguen para tomar decisiones en las diferentes áreas</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.10. Mecanismo de presentación directa de solicitudes, quejas y reclamos a disposición
                                  del público en relación con acciones u omisiones del sujeto obligado."
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/pqrs"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.10.1. Mecanismo de presentación directa de solicitudes, quejas y reclamos.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  1.12. Información sobre decisiones que pueden afectar al público.
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/AfectarPublico"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.12.1. Información sobre decisiones que puede afectar al público.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  onClick={() => handleModal7(true)}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">1.13. Entes y autoridades que lo vigilan.</span>
                                </a>
                              </li>
                            </ul>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "center" }}
            >
              <button
                className="btn btn-primary btn-block"
                type="button"
                data-toggle="collapse"
                data-target="#collapse2"
                aria-expanded="false"
                aria-controls="collapse2"
                style={{ padding: "23px 11px", backgroundColor: "#f44336" }}
                onClick={() => {
                  cerrar1(), setinformacionInteres(true);
                }}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <h5 style={{ fontWeight: 500 }}>
                      2. NORMATIVA
                    </h5>
                  </GridItem>
                </GridContainer>
              </button>
              {informacionInteres && (
                <GridContainer>
                  <GridItem xs={12} md={12} style={{ textAlign: "left" }}>
                    <div className="collapse" id="collapse2">
                      <div className="card card-body">
                        <GridContainer>
                          <GridItem md="6">
                            <ul
                              className="list-group list-group-flush"
                              style={{
                                fontWeight: 500,
                                fontFamily: "'Noto Sans SC', sans-serif"
                              }}
                            >
                              <li
                                className="list-group-item"
                                style={{
                                  borderBottom: "1px solid #ccc5c5",
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                2.1. Normativa de la entidad o autoridad.
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/Normativa"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">2.1.1. Leyes.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/Normativa"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">2.1.2. Decreto Único Reglamentario.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/Normativa"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto"> 2.1.3. Normativa aplicable.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="http://svrpubindc.imprenta.gov.co/diario/"
                                  tag={Link}
                                  target='_black'
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">2.1.4. Vínculo al Diario o Gaceta Oficial.</span>
                                </a>
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem md="6">
                            <ul
                              className="list-group list-group-flush"
                              style={{
                                fontWeight: 500,
                                fontFamily: "'Noto Sans SC', sans-serif"
                              }}
                            >
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="/page/Normativa"
                                  tag={Link}
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto">2.1.5. Políticas, lineamientos y manuales.</span>
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  2.2. Búsqueda de normas.
                                </a>
                              </li>
                              <li
                                className="list-group-item"
                                style={{ borderBottom: "1px solid #ccc5c5" }}
                              >
                                <a
                                  href="https://www.suin-juriscol.gov.co/"
                                  tag={Link}
                                  target='_black'
                                  style={{
                                    color: "black",
                                    fontWeight: 500,
                                    fontFamily: "'Noto Sans SC', sans-serif"
                                  }}
                                >
                                  <span className="texto"> 2.2.1. Sistema Único de Información Normativa – SUIN.</span>
                                </a>
                              </li>
                            </ul>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "center" }}
            >
              <button
                className="btn btn-primary btn-block"
                type="button"
                data-toggle="collapse"
                data-target="#collapse3"
                aria-expanded="false"
                aria-controls="collapse3"
                style={{ padding: "23px 11px", backgroundColor: "#f44336" }}
                onClick={() => {
                  cerrar1(), setTalentoHumano(true);
                }}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <div>
                      <h5 style={{ fontWeight: 500, boxSizing: "border-box" }}>
                        4. PLANEACIÓN
                      </h5>
                    </div>
                  </GridItem>
                </GridContainer>
              </button>
              {talentoHumano && (
                <GridContainer>
                  <GridItem md={12} style={{ textAlign: "left" }}>
                    <div className="collapse" id="collapse3">
                      <div className="card card-body">
                        <GridContainer>
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                              }}
                            >
                              <a
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                4.7 Informes de gestión, evaluación y auditoría.
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{ borderBottom: "1px solid #ccc5c5" }}
                            >
                              <a
                                onClick={() => handleModal6(true)}
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">4.7.4. Informes a organismos de inspección, vigilancia y control.</span>
                              </a>
                            </li>
                          </ul>
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li
                              className="list-group-item"
                              style={{ borderBottom: "1px solid #ccc5c5" }}
                            >
                              <a
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                4.7.5. Planes de mejoramiento:
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="https://www.supernotariado.gov.co/transparencia/planeacion-presupuesto-e-informes/auditorias-de-gestion-calidad-e-integrales/"
                                tag={Link}
                                target='_black'
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">4.7.5.b. Enlace al organismo de control donde se encuentren los informes que éste ha elaborado
                                  en relación con el sujeto obligado.</span>
                              </a>
                            </li>
                          </ul>
                        </GridContainer>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "center" }}
            >
              <button
                className="btn btn-primary btn-block"
                type="button"
                data-toggle="collapse"
                data-target="#collapse4"
                aria-expanded="false"
                aria-controls="collapse4"
                style={{ padding: "23px 11px", backgroundColor: "#f44336" }}
                onClick={() => {
                  cerrar1(), setnormativa(true);
                }}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <h5 style={{ fontWeight: 500 }}>5. TRÁMITES</h5>
                  </GridItem>
                </GridContainer>
              </button>
              {normativa && (
                <GridContainer>
                  <GridItem md={12} style={{ textAlign: "left" }}>
                    <div className="collapse" id="collapse4">
                      <div className="card card-body">
                        <ul
                          className="list-group list-group-flush"
                          style={{
                            fontWeight: 500,
                            fontFamily: "'Noto Sans SC', sans-serif"
                          }}
                        >
                          <li
                            className="list-group-item"
                            style={{ borderBottom: "1px solid #ccc5c5" }}
                          >
                            <a
                              style={{
                                color: "black",
                                fontWeight: 500,
                                fontFamily: "'Noto Sans SC', sans-serif"
                              }}
                            >
                              5.1 Informes de gestión, evaluación y auditoría.
                            </a>
                          </li>
                          <li
                            className="list-group-item"
                            style={{ borderBottom: "1px solid #ccc5c5" }}
                          >
                            <a
                              href="/page/Normativa"
                              tag={Link}
                              style={{
                                color: "black",
                                fontWeight: 500,
                                fontFamily: "'Noto Sans SC', sans-serif"
                              }}
                            >
                              <span className="texto">5.1.1. Normatividad que sustenta el trámite.</span>
                            </a>
                          </li>
                          <li
                            className="list-group-item"
                            style={{ borderBottom: "1px solid #ccc5c5" }}
                          >
                            <a
                              href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf"
                              tag={Link}
                              style={{
                                color: "black",
                                fontWeight: 500,
                                fontFamily: "'Noto Sans SC', sans-serif"
                              }}
                            >
                              <span className="texto">5.1.2. Procesos.</span>
                            </a>
                          </li>
                          <li
                            className="list-group-item"
                            style={{ borderBottom: "1px solid #ccc5c5" }}
                          >
                            <a
                              href="/page/Tarifas"
                              tag={Link}
                              style={{
                                color: "black",
                                fontWeight: 500,
                                fontFamily: "'Noto Sans SC', sans-serif"
                              }}
                            >
                              <span className="texto"> 5.1.3. Costos asociados.</span>
                            </a>
                          </li>
                          <li
                            className="list-group-item"
                            style={{ borderBottom: "1px solid #ccc5c5" }}
                          >
                            <a
                              href="/page/InfoTramites"
                              tag={Link}
                              style={{
                                color: "black",
                                fontWeight: 500,
                                fontFamily: "'Noto Sans SC', sans-serif"
                              }}
                            >
                              <span className="texto"> 5.1.4. Formatos y/o formularios asociados.</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "center" }}
            >
              <button
                className="btn btn-primary btn-block"
                type="button"
                data-toggle="collapse"
                data-target="#collapse7"
                aria-expanded="false"
                aria-controls="collapse7"
                style={{ padding: "23px 11px", backgroundColor: "#f44336" }}
                onClick={() => {
                  cerrar1(), setmecanismoControl(true);
                }}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <h5 style={{ fontWeight: 500 }}>
                      6. PARTICIPA
                    </h5>
                  </GridItem>
                </GridContainer>
              </button>
              <GridItem md={12}>
                {mecanismoControl && (
                  <GridContainer>
                    <GridItem xs={12} md={12} style={{ textAlign: "left" }}>
                      <div className="collapse" id="collapse7">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li
                              className="list-group-item"
                              onClick={() => handleModal1(true)}
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                color: "black",
                                fontWeight: 500,
                                fontFamily: "'Noto Sans SC', sans-serif"
                              }}
                            >
                              <span className="texto"> 6.1 Descripción General. </span>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                color: "black",
                                fontWeight: 500,
                                fontFamily: "'Noto Sans SC', sans-serif"
                              }}
                            >
                              <a
                                href="/page/participa"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif",
                                  cursor: 'pointer',
                                }}
                              >
                                <span className="texto">6.2 Estructura y Secciones del menú "PARTICIPA".</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
              </GridItem>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "center" }}
            >
              <button
                className="btn btn-primary btn-block"
                type="button"
                data-toggle="collapse"
                data-target="#collapse9"
                aria-expanded="false"
                aria-controls="collapse9"
                style={{ padding: "23px 11px", backgroundColor: "#f44336" }}
                onClick={() => {
                  cerrar1(), setTramites(true);
                }}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <h5 style={{ fontWeight: 500 }}>7. DATOS ABIERTOS</h5>
                  </GridItem>
                </GridContainer>
              </button>
              <GridItem md={12}>
                {tramites && (
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ textAlign: "left" }}
                    >
                      <div className="collapse" id="collapse9">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                              }}
                            >
                              <a
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                7.1 Instrumentos de gestión de la información.
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/GestionInformacion"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">7.1.1 Registros de activos de información</span>
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/ClasificadaReservada"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">7.1.2 Índice de información clasificada y reservada.</span>
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/GestionInformacion"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">7.1.3 Esquema de publicación de la información</span>
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/GestionDocumental"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">7.1.4 Programa de gestión documental.</span>
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/RetencionDocumental"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">7.1.5. Tablas de retención documental.</span>
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                              }}
                            >
                              <a
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                7.2 Sección de Datos Abiertos
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="http://datos.gov.co/"
                                tag={Link}
                                target='_black'
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">7.2.1 Datos Abiertos</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
              </GridItem>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <button
                className="btn btn-primary btn-block"
                type="button"
                data-toggle="collapse"
                data-target="#collapse10"
                aria-expanded="false"
                aria-controls="collapse10"
                style={{ padding: "23px 11px", backgroundColor: "#f44336" }}
                onClick={() => {
                  cerrar1(), setinstrumentos(true);
                }}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <h5 style={{ fontWeight: 500 }}>
                      8. INFORMACIÓN ESPECÍFICA PARA GRUPOS DE INTERÉS
                    </h5>
                  </GridItem>
                </GridContainer>
              </button>
              <GridItem md="12">
                {instrumentos && (
                  <GridContainer>
                    <GridItem md={12} style={{ textAlign: "left" }}>
                      <div className="collapse" id="collapse10">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                              }}
                            >
                              <a
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                8.1. Información para  Grupos Específicos.
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/InfoAdolescentes"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">8.1.1. Información para niños, niñas y adolescentes.</span>
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/InfoMujeres"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">8.1.2. Información para mujeres.</span>
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/InfOtros"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">8.1.3. Otros de grupos de interés.</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
              </GridItem>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <button
                className="btn btn-primary btn-block"
                type="button"
                data-toggle="collapse"
                data-target="#collapse11"
                aria-expanded="false"
                aria-controls="collapse11"
                style={{ padding: "23px 11px", backgroundColor: "#f44336" }}
                onClick={() => {
                  cerrar1(), settransparencia(true);
                }}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <h5 style={{ fontWeight: 500 }}>
                      9. OBLIGACIÓN DE REPORTE DE INFORMACIÓN ESPECÍFICA POR PARTE DE LA ENTIDAD
                    </h5>
                  </GridItem>
                </GridContainer>
              </button>
              <GridItem md={12}>
                {transparencia && (
                  <GridContainer>
                    <GridItem md={12} style={{ textAlign: "left" }}>
                      <div className="collapse" id="collapse11">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/procesos"
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">9.1. Normatividad Especial.</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
              </GridItem>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "center" }}
            >
              <button
                className="btn btn-primary btn-block"
                type="button"
                data-toggle="collapse"
                data-target="#collapse12"
                aria-expanded="false"
                aria-controls="collapse12"
                style={{ padding: "23px 11px", backgroundColor: "#f44336" }}
                onClick={() => {
                  cerrar1(), setweb(true);
                }}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <h5 style={{ fontWeight: 500 }}>ATENCIÓN Y SERVICIOS A LA CIUDADANÍA</h5>
                  </GridItem>
                </GridContainer>
              </button>
              <GridItem md={12}>
                {web && (
                  <GridContainer>
                    <GridItem md={12} style={{ textAlign: "left" }}>
                      <div className="collapse" id="collapse12">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/InfoTramites"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">1. Trámites, Otros Procedimientos Administrativos y consultas de acceso a información pública.</span>
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                              }}
                            >
                              <a
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                2. Canales de atención y pida una cita.
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/pqrs"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto">2.1. Canales habilitados para la atención a la ciudadanía</span>
                              </a>
                            </li>
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/home#contacts"
                                tag={Link}      
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto"> 2.2. Mecanismo para que el usuario pueda agendar una cita para atención presencial, e indicar los
                                  horarios de atención en sedes físicas"</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
              </GridItem>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ textAlign: "center" }}
            >
              <button
                className="btn btn-primary btn-block"
                type="button"
                data-toggle="collapse"
                data-target="#collapse13"
                aria-expanded="false"
                aria-controls="collapse13"
                style={{ padding: "23px 11px", backgroundColor: "#f44336" }}
                onClick={() => {
                  cerrar1(), setproteccion(true);
                }}
              >
                <GridContainer>
                  <GridItem md={12}>
                    <h5 style={{ fontWeight: 500 }}>
                      NOTICIAS
                    </h5>
                  </GridItem>
                </GridContainer>
              </button>
              <GridItem md={12}>
                {proteccion && (
                  <GridContainer>
                    <GridItem md={12} style={{ textAlign: "left" }}>
                      <div className="collapse" id="collapse13">
                        <div className="card card-body">
                          <ul
                            className="list-group list-group-flush"
                            style={{
                              fontWeight: 500,
                              fontFamily: "'Noto Sans SC', sans-serif"
                            }}
                          >
                            <li
                              className="list-group-item"
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                              }}
                            >
                              <a
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                12.1. Sección de Noticias.
                              </a>
                            </li>
                            <li
                              className="list-group-item" z
                              style={{
                                borderBottom: "1px solid #ccc5c5",
                                cursor: "pointer"
                              }}
                            >
                              <a
                                href="/page/home#projects"
                                tag={Link}
                                style={{
                                  color: "black",
                                  fontWeight: 500,
                                  fontFamily: "'Noto Sans SC', sans-serif"
                                }}
                              >
                                <span className="texto"> 12.1.1. Sección de noticias.</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                )}
              </GridItem>
            </GridItem>
          </GridContainer>
        </div>
      </div >
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal1}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleModal1(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => handleModal1(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Página en Construcción
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <img
            height="300px"
            alt="Obrero animados con un letrero mencionando que la página se encuentra en construcción"
            className="img-rounded img-fluid"
            src={require("assets/img/pag.png")}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => handleModal1(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal2}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleModal2(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => handleModal2(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Directorio de entidades
          </h4>
          <hr></hr>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <Card style={{ borderRadius: "10px" }}>
            <CardBody className="text-center">
              <h4 className="text-center" style={{ marginBottom: "45px" }}>Ministerio de Justicia y del Derecho</h4>
              <a href="https://www.minjusticia.gov.co/"
                role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                <b style={{ fontSize: "16px" }}>Ir a página</b>
              </a>
            </CardBody>
          </Card>
          <Card style={{ borderRadius: "10px" }}>
            <CardBody className="text-center">
              <h4 className="text-center" style={{ marginBottom: "15px" }}>Superintendencia de notariado y registro</h4>
              <a href="https://www.supernotariado.gov.co/"
                role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                <b style={{ fontSize: "16px" }}>Ir a página</b>
              </a>
            </CardBody>
          </Card>
          <Card style={{ borderRadius: "10px" }}>
            <CardBody className="text-center">
              <h4 className="text-center" style={{ marginBottom: "15px" }}>Registraduría Nacional de Estado Civil</h4>
              <a href="https://www.registraduria.gov.co/"
                role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                <b style={{ fontSize: "16px" }}>Ir a página</b>
              </a>
            </CardBody>
          </Card>
          <Card style={{ borderRadius: "10px" }}>
            <CardBody className="text-center">
              <h4 className="text-center" style={{ marginBottom: "15px" }}>Procuraduría General de la Nación</h4>
              <a href="https://www.procuraduria.gov.co/portal/"
                role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                <b style={{ fontSize: "16px" }}>Ir a página</b>
              </a>
            </CardBody>
          </Card>
          <Card style={{ borderRadius: "10px" }}>
            <CardBody className="text-center">
              <h4 className="text-center" style={{ marginBottom: "15px" }}>Ventanilla Única de Registro VUR</h4>
              <a href="https://www.vur.gov.co/"
                role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                <b style={{ fontSize: "16px" }}>Ir a página</b>
              </a>
            </CardBody>
          </Card>
          <Card style={{ borderRadius: "10px" }}>
            <CardBody className="text-center">
              <h4 className="text-center" style={{ marginBottom: "15px" }}>Suin Juriscol</h4>
              <a href="https://www.suin-juriscol.gov.co/"
                role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                <b style={{ fontSize: "16px" }}>Ir a página</b>
              </a>
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => handleModal2(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal3}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModal3(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setModal3(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Glosario
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <ul>
            <li>
              <p>
                <a
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 400,
                    fontFamily:
                      "'Noto Sans SC', sans-serif"
                  }}
                  href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/06/diligencias.pdf"
                  target="_blank"
                >
                  <u>
                    Se remite a la página 46 del
                    documento "Cartilla de Diligencias
                    Notariales" que contiene el glosario
                    de los términos más usados en
                    derecho notarial
                  </u>
                </a>
              </p>
            </li>
          </ul>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setModal3(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal4}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleModal4(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => handleModal4(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Funciones y Deberes
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <ul>
            <li>
              <p>
                <a
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    fontFamily:
                      "'Noto Sans SC', sans-serif"
                  }}
                  href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html"
                  target="_blank"
                >
                  <u>DECRETO 960 DE 1970</u>
                </a>
              </p>
            </li>
            <li>
              <p>
                <a
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    fontFamily:
                      "'Noto Sans SC', sans-serif"
                  }}
                  href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=74174"
                  target="_blank"
                >
                  <u>
                    Decreto 1069 de 2015 Sector Justicia
                    y del Derecho
                  </u>
                </a>
              </p>
            </li>
          </ul>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => handleModal4(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal5}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleModal5(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => handleModal5(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Directorio de agremiaciones, asociaciones y
            otros grupos de interés
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <ul>
            <li>
              <p>
                <a
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    fontFamily:
                      "'Noto Sans SC', sans-serif"
                  }}
                  href="https://www.ucnc.com.co/sitio/"
                  target="_blank"
                >
                  <u>https://www.ucnc.com.co/sitio/</u>
                </a>
              </p>
            </li>
          </ul>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => handleModal5(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal6}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleModal6(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => handleModal6(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Informes de gestión, evaluación y auditoría
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              Los informes que presentan los Señores
              Notarios a la Superintendencia de Notariado
              y Registro son:
            </p>
            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              Informe Estadistico Notarial, cuya
              información financiera y contable es
              reservada. Artículo 61 del Código de
              Comercio. El informe estadistico que remiten
              mensualmente las Notarías a la SNR a través
              de los correos habilitados para ello, según
              la Instrucción Administrativa 4 de 2019.
            </p>
            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              Los informes que rinden los Señores Notarios
              a la UIAF son:
            </p>

            <ul
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              <li>
                Reporte de Operación Notarial (RON).
              </li>
              <li>
                Reporte de Operaciones intentadas y
                operaciones sospechosas ROS.
              </li>
              <li>
                Reporte de ausencia de operaciones
                sospechosos
              </li>
              <li>
                Reporte de transacciones en efectivo.
              </li>
              <li>
                Reporte de ausencia de transacciones en
                efectivo.
              </li>
              <li>
                Reporte de adjudicaciones en remate.
              </li>
            </ul>
            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              Los informes que allegan los Señores
              Notarios a la DIAN son:
            </p>

            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              Información exógena.
            </p>
            <p>
              <a
                href="https://www.supernotariado.gov.co/transparencia/planeacion-presupuesto-e-informes/auditorias-de-gestion-calidad-e-integrales/"
                tag={Link}
                target='_black'
                style={{
                  color: "black",
                  fontWeight: 500,
                  fontFamily: "'Noto Sans SC', sans-serif"
                }}>
              <span className="texto">Enlace al organismo de control donde se encuentren los informes que éste ha elaborado
                en relación con el sujeto obligado.</span>
              </a>
            </p>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => handleModal6(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal7}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleModal7(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => handleModal7(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Entes de Control que vigilan a las notarías
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              La orientación, inspección, vigilancia y
              control a las Notarías lo ejerce la
              Superintendencia de Notariado y Registro en
              virtud del artículo 4 del Decreto 2723 de
              2014.
            </p>
            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              La Superintendencia de Notariado y Registro
              ejerce el control conforme a los informes
              estadisticos que rinden mensualmente los
              Notarios, así como con ocasión de las
              visitas previstas en los artículos
              2.2.6.1.6.3.1 y siguientes del Decreto 1069
              de 2015.
            </p>
            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              El control que ejerce la Superintendencia de
              Notariado y Registro sobre las Notarias es
              de naturaleza administrativa y
              disciplinaria.
            </p>
            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              Sede Principal y Atención al Ciudadano</p>
            <p style={{
              fontFamily: "'Noto Sans SC', sans-serif",
              textAlign: "justify"
            }}>Calle 26 No. 13-49 Interior 201, Bogotá D.C. Colombia.</p>
            <p style={{
              fontFamily: "'Noto Sans SC', sans-serif",
              textAlign: "justify"
            }}>Código postal : # 110311 – 110311000</p>
            <p style={{
              fontFamily: "'Noto Sans SC', sans-serif",
              textAlign: "justify"
            }}>Nit: 899.999.007-0</p>
            <p style={{
              fontFamily: "'Noto Sans SC', sans-serif",
              textAlign: "justify"
            }}>Horario de Atención : 08:00 a.m a 05:00 p.m.</p>
            <p style={{
              fontFamily: "'Noto Sans SC', sans-serif",
              textAlign: "justify"
            }}>Telefono – Conmutador : 57+(601) 514 0313</p>
            <p style={{
              fontFamily: "'Noto Sans SC', sans-serif",
              textAlign: "justify"
            }}>Conmutador Atención al Ciudadano : 57+(601) 514 0313</p>
            <p style={{
              fontFamily: "'Noto Sans SC', sans-serif",
              textAlign: "justify"
            }}>Correo Institucional : correspondencia@supernotariado.gov.co</p>
            <p style={{
              fontFamily: "'Noto Sans SC', sans-serif",
              textAlign: "justify"
            }}>Sitio Web :  https://www.supernotariado.gov.co/</p>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => handleModal7(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal10}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModal10(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setModal10(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Información para población vulnerable
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif",
                textAlign: "justify"
              }}
            >
              <ul>
                <li>La Ley 1997 de 2019 que permite la inscripción en el registro civil de los hijos de los nacionales venezolanos.</li>
                <li>La declaración extraproceso rendida por la mujer cabeza de familia como un acto exento de derechos notariales.</li>
                <li>El otorgamiento de la escritura pública para el cambio de nombre y para la corrección de errores u omisiones en el Registro del Estado Civil de miembros de comunidades indigenas.</li>
              </ul>
            </p>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setModal10(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal8}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModal8(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setModal8(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Informe de Peticiones, quejas, reclamos,
            denuncias y solicitudes de acceso a la
            información
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <img
              alt="Imagen de la ciudad de Bogotá de Colombia"
              className="img-rounded img-responsive img-fluid"
              src={require("assets/img/sections/bogota12.jpg")}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setModal8(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal9}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModal9(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setModal9(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Medios de seguimiento para la consulta del
            estado de las solicitudes de información
            pública
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <p
              style={{
                fontFamily: "'Noto Sans SC', sans-serif"
              }}
            >
              Texto Pendiente
            </p>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setModal9(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={modal11}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={false}
        onClose={() => setModal11(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setModal11(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4
            className={classes.modalTitle}
            style={{
              fontFamily: "'Noto Sans SC', sans-serif"
            }}
          >
            Certificado de Seguridad
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <img src={require('assets/img/sections/certificadohttps.png')} width='100%' />
            <center>
              <a href='https://aws.amazon.com/certificate-manager/' target="_blank">Más Información</a>
            </center>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setModal11(false)}
            className="btn-round"
            color="primary"
            type="button"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}
