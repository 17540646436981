import React from 'react';
// core components
import GridContainer from 'componentsPage/Grid/GridContainer.js';
import GridItem from 'componentsPage/Grid/GridItem.js';
import InfoArea from 'componentsPage/InfoArea/InfoArea.js';
import ReactCardFlip from 'react-card-flip';
// @material-ui icons
import {Dashboard} from '@material-ui/icons';
import {Description} from '@material-ui/icons';
import {Gavel} from '@material-ui/icons';
import {AssignmentInd} from '@material-ui/icons';
import Button from 'componentsPage/CustomButtons/Button.js';
import {Send as SendIcon} from '@material-ui/icons';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import descriptionStyle from 'assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js';

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription({ ...rest }) {
  const classes = useStyles();

  const [isFlipped1, setIsFlipped1] = React.useState(false);
  const [isFlipped2, setIsFlipped2] = React.useState(false);
  const [isFlipped3, setIsFlipped3] = React.useState(false);

  const rotar = (e, index, indexset) => {
    e.preventDefault();
    indexset(!index);
  };

  return (
    <div className="cd-section" {...rest}>
      <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem md={8} sm={8}>
              <h4 className={classes.description}>
                La Notaría 11 de Bucaramanga le da la bienvenida a su portal web y le ofrece los siguientes servicios.
              </h4>
            </GridItem>
          </GridContainer>
          <div className={classes.features}>
            <GridContainer>
              <GridItem md={4} sm={4}>
                <ReactCardFlip isFlipped={isFlipped1} flipDirection="horizontal">
                  <InfoArea
                    title="Escrituración"
                    description={
                      <span>
                        <p>
                          Documento que contiene la declaración de voluntad de una o varias personas, emitidas ante el
                          notario, para hacer un determinado contrato o un acto jurídico individual.
                        </p>
                        <a onClick={(e) => rotar(e, isFlipped1, setIsFlipped1)}>
                          <Button round color="danger">
                            Requisitos{' '}
                          </Button>
                        </a>
                      </span>
                    }
                    icon={Description}
                    iconColor="danger"
                    vertical={true}
                  />
                  <InfoArea
                    className={classes.info4}
                    title="Requisitos"
                    description={
                      <div>
                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
                          <ListItem button>
                            <ListItemIcon>
                              <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Copia de cedulas" />
                          </ListItem>
                          <ListItem button>
                            <ListItemIcon>
                              <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Copia de la escritura de adquisición" />
                          </ListItem>
                          <ListItem button>
                            <ListItemIcon>
                              <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Certificado de libertad" />
                          </ListItem>
                          <ListItem button>
                            <ListItemIcon>
                              <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Paz y salvo de impuesto predial y de valorización." />
                          </ListItem>
                          <ListItem button>
                            <ListItemIcon>
                              <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Paz y salvo de administración.." />
                          </ListItem>
                        </List>
                        <p>
                          <a onClick={(e) => rotar(e, isFlipped1, setIsFlipped1)}>
                            <Button round color="danger">
                              Atras{' '}
                            </Button>
                          </a>
                        </p>
                      </div>
                    }
                    icon={Dashboard}
                    iconColor="danger"
                  />
                </ReactCardFlip>
              </GridItem>
              <GridItem md={4} sm={4}>
                <ReactCardFlip isFlipped={isFlipped2} flipDirection="horizontal">
                  <InfoArea
                    title="Registro Civil"
                    description={
                      <span>
                        <p>
                          Es un documento indispensable mediante el cual la persona prueba ante la familia y la sociedad
                          su existencia y le permite identificarse, ejercer sus derechos y cumplir sus obligaciones.
                        </p>
                        <a onClick={(e) => rotar(e, isFlipped2, setIsFlipped2)}>
                          <Button round color="danger">
                            Requisitos{' '}
                          </Button>
                        </a>
                      </span>
                    }
                    icon={AssignmentInd}
                    iconColor="danger"
                    vertical={true}
                  />
                  <InfoArea
                    className={classes.info4}
                    title="Requisitos"
                    icon={Dashboard}
                    iconColor="danger"
                    description={
                      <div>
                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
                          <ListItem button>
                            <ListItemIcon>
                              <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Certificado de nacido vivo." />
                          </ListItem>
                          <ListItem button>
                            <ListItemIcon>
                              <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Documentos de identificación de los padres." />
                          </ListItem>
                        </List>
                        <span>La inscripción de registro civil no tiene costo.</span>
                        <p>
                          <a onClick={(e) => rotar(e, isFlipped2, setIsFlipped2)}>
                            <Button round color="danger">
                              Atras{' '}
                            </Button>
                          </a>
                        </p>
                      </div>
                    }
                  />
                </ReactCardFlip>
              </GridItem>
              <GridItem md={4} sm={4}>
                <ReactCardFlip isFlipped={isFlipped3} flipDirection="horizontal">
                  <InfoArea
                    title="Autenticaciones"
                    description={
                      <span>
                        <p>
                          Es cuando el notario da testimonio escrito de las firma que aparecen en un documento privado
                          fueron puestas en su presencia, estableciendo la identidad de los firmantes.
                        </p>
                        <a href="/page/prueba" onClick={(e) => rotar(e, isFlipped3, setIsFlipped3)}>
                          <Button round color="danger">
                            Requisitos{' '}
                          </Button>
                        </a>
                      </span>
                    }
                    icon={Gavel}
                    iconColor="danger"
                    vertical={true}
                  />
                  <InfoArea
                    className={classes.info4}
                    title="Requisitos"
                    icon={Dashboard}
                    iconColor="danger"
                    description={
                      <div>
                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
                          <ListItem button>
                            <ListItemIcon>
                              <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Asistir personalmente a la notaría. Puede hacerse también a domicilio." />
                          </ListItem>
                          <ListItem button>
                            <ListItemIcon>
                              <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Llevar la cédula de ciudadanía." />
                          </ListItem>
                          <ListItem button>
                            <ListItemIcon>
                              <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Llevar el documento que se va a autenticar." />
                          </ListItem>
                        </List>
                        <p>
                          <a onClick={(e) => rotar(e, isFlipped3, setIsFlipped3)}>
                            <Button round color="danger">
                              Atras{' '}
                            </Button>
                          </a>
                        </p>
                      </div>
                    }
                  />
                </ReactCardFlip>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
