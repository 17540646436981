import {CARGAR_NOTARIA} from '../types';

const initialState = {
    notaria: {}
}

export default function(state = initialState, action){
    switch(action.type){
        case CARGAR_NOTARIA:
            return{
                ...state,
                notaria: action.payload
            }
        
        default: 
            return state
    }
}

