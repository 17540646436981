import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

function SectionExamples() {
  return (
    <>
      <div id="oficinas" className="section section-examples section-dark" style={{ paddingTop:"25%" }} >
        <Container>
          <Row>
            <Col md="4">
              <Card className="card-image card-hover-effect">
                <Link to="/" target="_blank">
                  <img
                    alt="Hombre sentado en un escritorio mirando hacia la camara en su oficina"
                    src={require("assets/img/IMG_20231215_132534.jpg")}
                    style={{  width: "50%" }}
                  />
                </Link>
              </Card>
              <Card className="card-image card-hover-effect">
                <Link to="/" target="_blank">
                  <img
                    alt="Un hombre sentando en un mueble de una grande oficina"
                    src={require("assets/img/IMG_20231215_124013.jpg")}
                    style={{  width: "50%" }}
                  />
                </Link>
              </Card>
              <Card className="card-image card-hover-effect">
                <Link to="/" target="_blank">
                  <img
                    alt="Cuatro personas sentadas en una mesa para reuniones en una oficina"
                    src={require("assets/img/IMG_20231215_123835.jpg")}
                    style={{  width: "50%" }}
                  />
                </Link>
              </Card>
              <Card className="card-image">
                <Link to="/" target="_blank">
                  <img
                    alt="Una mujer sentada en un escritorio de oficina"
                    src={require("assets/img/IMG_20231215_123300.jpg")}
                    style={{  width: "50%" }}
                  />
                </Link>
              </Card>
              <Card className="card-image card-hover-effect">
                <Link to="/" target="_blank">
                  <img
                    alt="Dos mujeres sentadas en muebles diferentes en la sala de espera de una oficina"
                    src={require("assets/img/IMG_20231215_121852.jpg")}
                    style={{  width: "50%" }}
                  />
                </Link>
              </Card>  
            </Col>
            <Col md="4">
            
            <Card className="card-image">
                <Link to="/" target="_blank">
                  <img
                    alt="cinco personas atendiendo en la recepción de la notaría"
                    src={require("assets/img/IMG_20231215_115741.jpg")}
                    style={{  width: "50%" }}
                  />
                </Link>
              </Card>
              <Card className="card-image">
                <Link to="/" target="_blank">
                  <img
                    alt="Recepción más pequeña de la notaría 10 de Bucaramanga"
                    src={require("assets/img/IMG_20231215_100750.jpg")}
                    style={{  width: "50%" }}
                  />
                </Link>
              </Card>
              <Card className="card-image">
                <Link to="/" target="_blank">
                  <img
                    alt="Una mujer sentada en una oficina para atender reuniones"
                    src={require("assets/img/IMG_20231215_100739.jpg")}
                    style={{  width: "50%" }}
                  />
                </Link>
              </Card>
              <Card className="card-image">
                <Link to="/" target="_blank">
                  <img
                    alt="Una mujer sentada en una oficina para atender reuniones"
                    src={require("assets/img/WhatsApp Image 2024-04-04 at 1.32.54 PM (1).jpeg")}
                    style={{  width: "50%" }}
                  />
                </Link>
              </Card>
              <Card className="card-image">
                <Link to="/" target="_blank">
                  <img
                    alt="Una mujer sentada en una oficina para atender reuniones"
                    src={require("assets/img/WhatsApp Image 2024-04-04 at 1.32.54 PM.jpeg")}
                    style={{  width: "50%" }}
                  />
                </Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionExamples;
