/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function GestionInformacion() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/sections/bucaramanga.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Gestion Informacion</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ paddingTop: '50px', paddingBottom: '30px' }}>
          <h4>Gestion Informacion</h4>
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ paddingTop: '50px', paddingBottom: '30px' }}>
          <center>
            <a
              href="http://datos.gov.co/"
              target='_black'
              style={{
                color: "black",
                fontWeight: 500,
                fontFamily: "'Noto Sans SC', sans-serif"
              }}
            >
            <span className="texto">Enlace a Datos Abiertos</span>
            </a>  
          </center>
          <center className="mt-5">
            <a href={"https://drive.google.com/file/d/1jXNZy8wKqS3hs-NyToXR3hvH66Aab86A/view?usp=sharing"} style={{textDecoration: "none !important"}} target="_blank">
              <img
                alt="PROTECCION DE DATOS PERSONALES"
                width="80px"
                src={require("assets/img/sections/pdf.jpg")}
              ></img>
              <h6 className="my-3" >Registro de Activos de Informacion</h6>
            </a>
          </center>
        </div>
      </div>
    </div>
  );
}
