/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";


import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function MisionVision() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/sections/bucaramanga.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title} >Misión y Visión</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:'50px', paddingBottom:'30px'}}>  
          <GridContainer className="ml-auto mr-auto">
            <GridItem md="11" >
              <h3 className="title-uppercase" style={{fontWeight:500, fontFamily: "'Noto Sans SC', sans-serif"}} >
                Visión:
              </h3>
              <div>
                <p className="my-4" style={{  fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
                  El Notario 11 de Bucaramanga, en seis años, tendrá el servicio de centro de conciliación y prestación del servicio público notarial por medios digitales de todos los actos de su competencia, brindando la seguridad jurídica y con altos parámetros eficiencia que nos permita alcanzar el reconocimiento Departamental.
                </p>
              </div>
            </GridItem>
            <GridItem md="11">
              <h3 className="title-uppercase" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
               Misión:
              </h3>
              <p className="my-4" style={{ fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
                Prestar el servicio público federativo notarial, garantizando los principios de legalidad, inmediatez, rogación, imparcialidad y buena fe con eficiencia y asegurando la conservación de los documentos a los usuarios del servicio.
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
